import React,{FC} from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const FullCalendarComponent: FC = () =>{

    
    return(
        <section className="calendar_section">
            <div className="row">
                <div className="col-lg-3 col-xl-3 col-xxl-2">
                    <div className="card h-100 bg-light">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Amy
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Chandru
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    David
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Murugan
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Nirmal
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Raj
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Ram
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Riya
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Tara
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                    />
                </div>
            </div>
        </section>
    )
}
export{FullCalendarComponent}