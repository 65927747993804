import React,{FC} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { AlertNotifications } from './alertNotifications'
import { EmailIntegration } from './EmailIntegration'
import { PersonalSettings } from './personalSettings'
import { ThemeBuilder } from './themeBuilder'



const Settings: FC = () => {

    return(
        <div className="settings_page p-4 h-100">
            <section>
                <div className="row">
                    <div className="card-group">
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="/settings/organization-settings">
                                <div className="card h-100 bs_1 mx-3">
                                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                    <img src={toAbsoluteUrl('/media/custom/project.svg')} alt="" className="icon mb-4"/>   
                                    <h3 className="text-capitalize mt-3 mb-3 text-center">organization</h3>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="/settings/user-settings">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Users Management</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Templates</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Integrations</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Alerts & Notification Settings</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Masters</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Form Builder</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">My Profile</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2 mb-3">
                            <a href="">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                        <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon mb-4"/>   
                                        <h3 className="text-capitalize mt-3 mb-3 text-center">Lead Settings</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* tabs start */}
                {/* <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Organization</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-personal-tab" data-bs-toggle="pill" data-bs-target="#pills-personal" type="button" role="tab" aria-controls="pills-personal" aria-selected="false">Personal Settings</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-feed-tab" data-bs-toggle="pill" data-bs-target="#pills-feed" type="button" role="tab" aria-controls="pills-feed" aria-selected="false">Feed Management</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-email-tab" data-bs-toggle="pill" data-bs-target="#pills-email" type="button" role="tab" aria-controls="pills-email" aria-selected="false">Email Integration</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-alerts-tab" data-bs-toggle="pill" data-bs-target="#pills-alerts" type="button" role="tab" aria-controls="pills-alerts" aria-selected="false">Alerts & Notifications</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-masters-tab" data-bs-toggle="pill" data-bs-target="#pills-masters" type="button" role="tab" aria-controls="pills-masters" aria-selected="false">Masters Field Integration</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-builder-tab" data-bs-toggle="pill" data-bs-target="#pills-builder" type="button" role="tab" aria-controls="pills-builder" aria-selected="false">Form Builder</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-theme-tab" data-bs-toggle="pill" data-bs-target="#pills-theme" type="button" role="tab" aria-controls="pills-theme" aria-selected="false">Theme Builder</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-theme-tab" data-bs-toggle="pill" data-bs-target="#pills-theme" type="button" role="tab" aria-controls="pills-theme" aria-selected="false">Template Builder</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-xml-tab" data-bs-toggle="pill" data-bs-target="#pills-xml" type="button" role="tab" aria-controls="pills-xml" aria-selected="false">XML Builder</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-chat-tab" data-bs-toggle="pill" data-bs-target="#pills-chat" type="button" role="tab" aria-controls="pills-chat" aria-selected="false">Web Chat</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                        Organization
                    </div>
                    <div className="tab-pane fade" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                        <PersonalSettings/>
                    </div>
                    <div className="tab-pane fade" id="pills-feed" role="tabpanel" aria-labelledby="pills-feed-tab">
                        Feeds
                    </div>
                    <div className="tab-pane fade" id="pills-email" role="tabpanel" aria-labelledby="pills-email-tab">
                        <EmailIntegration/>
                    </div>
                    <div className="tab-pane fade" id="pills-alerts" role="tabpanel" aria-labelledby="pills-alerts-tab">
                        <AlertNotifications/>
                    </div>
                    <div className="tab-pane fade" id="pills-masters" role="tabpanel" aria-labelledby="pills-masters-tab">
                        Masters
                    </div>
                    <div className="tab-pane fade" id="pills-builder" role="tabpanel" aria-labelledby="pills-builder-tab">
                        Builder
                    </div>
                    <div className="tab-pane fade" id="pills-theme" role="tabpanel" aria-labelledby="pills-theme-tab">
                        <ThemeBuilder/>
                    </div>
                    <div className="tab-pane fade" id="pills-template" role="tabpanel" aria-labelledby="pills-template-tab">
                        Template builder
                    </div>
                    <div className="tab-pane fade" id="pills-xml" role="tabpanel" aria-labelledby="pills-xml-tab">
                        XML builder
                    </div>
                    <div className="tab-pane fade" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">
                        Web Chat
                    </div>
                </div> */}
            {/* tabs end */}
        </div>
    )
}

export {Settings}
