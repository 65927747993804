import React,{FC} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const TaskPage: FC = () => {

    return(
        <div className="taskpage">
            <div className="row">
                <div className="card-group">
                    <div className="col-sm-6 col-xxl-3 col-xl-4 mt-10 mb-3">
                        <div className="card h-100 mb-xl-8 mx-2 task_card bs_1">
                            <div className="card_status bg_secondary">
                                <p className="mb-0">Followup Call</p>
                            </div>
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="flexCheckChecked"/>
                                                    <label className="form-check-label id_label" htmlFor="flexCheckChecked">
                                                        235648
                                                    </label>
                                                </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Ram</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="/#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">Contact Type</small>
                                                                <p className="mb-0 fw-500">Buyer</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="task_content_single">
                                                        <div className="d-flex align-items-center mb-4">
                                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="icon me-2"/>
                                                            <p className="ml-2 mb-0 fixed_text">Buyer</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/project.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">Project</small>
                                                                <p className="mb-0 fw-500">Brigade Orchards</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/fromdate.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">From Date</small>
                                                                <p className="mb-0 fw-500">02 Jan 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/fromdate.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">To Date</small>
                                                                <p className="mb-0 fw-500">05 Apr 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">Buyer</small>
                                                                <p className="mb-0 fw-500">Kumar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-6 mb-3">
                                                    <div className="task_content_single">
                                                        <div className="d-flex align-items-start single_item">
                                                            <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="me-2"/>
                                                            <div className="d-flex flex-column">
                                                                <small className="text_light">Assign To</small>
                                                                <p className="mb-0 fw-500">Arjun</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex align-items-end flex-wrap">
                                            <a href="/#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative mb-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="/#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative mb-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <select className="form-select form-select-sm priority_select ms-2 mb-2" aria-label=".form-select-sm example">
                                                <option selected>Priority</option>
                                                <option value="1">High Priority</option>
                                                <option value="2">Low Priority</option>
                                                <option value="3">Medium Priority</option>
                                            </select>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option value="1">Completed</option>
                                                <option value="2">In Process</option>
                                                <option value="3">Cancel</option>
                                                <option value="4">Pending</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export {TaskPage}
