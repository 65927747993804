import React,{FC} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const LeadPage: FC = () => {

    return(
        <div className="leadpage">
            <div className="row">
                <div className="card-group">
                    <div className="col-sm-6 col-xxl-4 col-xl-6 mb-3">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 lead_card">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Raj</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Category</small>
                                                            <p className="mb-0 fw-500">Residential</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Property Type</small>
                                                            <p className="mb-0 fw-500">Flat</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Location</small>
                                                            <p className="mb-0 fw-500">Devanahalli</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Source</small>
                                                            <p className="mb-0 fw-500">Google Ads</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Project</small>
                                                            <p className="mb-0 fw-500">Brigade Orchards</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Furnishing</small>
                                                            <p className="mb-0 fw-500">Fully Furnished</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Builtup Area</small>
                                                            <p className="mb-0 fw-500">1200Sq.ft</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bedrooms</small>
                                                            <p className="mb-0 fw-500">1-2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bathrooms</small>
                                                            <p className="mb-0 fw-500">2-3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Assign To</small>
                                                            <p className="mb-0 fw-500">Arjun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Lead Priority</small>
                                                            <p className="mb-0 fw-500">High</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Date</small>
                                                            <p className="mb-0 fw-500">30 Dec 2022</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    100
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    10
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    41
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    26
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            {/* <a className="btn btn-sm icon_bg rounded-circle position-relative">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a> */}
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                               <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-4 col-xl-6 mb-3">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 lead_card">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Raj</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-25.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Category</small>
                                                            <p className="mb-0 fw-500">Residential</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Property Type</small>
                                                            <p className="mb-0 fw-500">Flat</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Location</small>
                                                            <p className="mb-0 fw-500">Devanahalli</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Source</small>
                                                            <p className="mb-0 fw-500">Google Ads</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Project</small>
                                                            <p className="mb-0 fw-500">Brigade Orchards</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Furnishing</small>
                                                            <p className="mb-0 fw-500">Fully Furnished</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Builtup Area</small>
                                                            <p className="mb-0 fw-500">1200Sq.ft</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bedrooms</small>
                                                            <p className="mb-0 fw-500">1-2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bathrooms</small>
                                                            <p className="mb-0 fw-500">2-3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Assign To</small>
                                                            <p className="mb-0 fw-500">Arjun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Lead Priority</small>
                                                            <p className="mb-0 fw-500">High</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Date</small>
                                                            <p className="mb-0 fw-500">30 Dec 2022</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    100
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    10
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    41
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    26
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            {/* <a className="btn btn-sm icon_bg rounded-circle position-relative">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a> */}
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                               <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-4 col-xl-6 mb-3">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 lead_card">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Raj</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-17.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Category</small>
                                                            <p className="mb-0 fw-500">Residential</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Property Type</small>
                                                            <p className="mb-0 fw-500">Flat</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Location</small>
                                                            <p className="mb-0 fw-500">Devanahalli</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Source</small>
                                                            <p className="mb-0 fw-500">Google Ads</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Project</small>
                                                            <p className="mb-0 fw-500">Brigade Orchards</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Furnishing</small>
                                                            <p className="mb-0 fw-500">Fully Furnished</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Builtup Area</small>
                                                            <p className="mb-0 fw-500">1200Sq.ft</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bedrooms</small>
                                                            <p className="mb-0 fw-500">1-2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bathrooms</small>
                                                            <p className="mb-0 fw-500">2-3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Assign To</small>
                                                            <p className="mb-0 fw-500">Arjun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Lead Priority</small>
                                                            <p className="mb-0 fw-500">High</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Date</small>
                                                            <p className="mb-0 fw-500">30 Dec 2022</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    100
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    10
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    41
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    26
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            {/* <a className="btn btn-sm icon_bg rounded-circle position-relative">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a> */}
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                               <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-4 col-xl-6 mb-3">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 lead_card">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Raj</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Category</small>
                                                            <p className="mb-0 fw-500">Residential</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Property Type</small>
                                                            <p className="mb-0 fw-500">Flat</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Location</small>
                                                            <p className="mb-0 fw-500">Devanahalli</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Source</small>
                                                            <p className="mb-0 fw-500">Google Ads</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Project</small>
                                                            <p className="mb-0 fw-500">Brigade Orchards</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Furnishing</small>
                                                            <p className="mb-0 fw-500">Fully Furnished</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Builtup Area</small>
                                                            <p className="mb-0 fw-500">1200Sq.ft</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bedrooms</small>
                                                            <p className="mb-0 fw-500">1-2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bathrooms</small>
                                                            <p className="mb-0 fw-500">2-3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Assign To</small>
                                                            <p className="mb-0 fw-500">Arjun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Lead Priority</small>
                                                            <p className="mb-0 fw-500">High</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Date</small>
                                                            <p className="mb-0 fw-500">30 Dec 2022</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    100
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    10
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    41
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    26
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            {/* <a className="btn btn-sm icon_bg rounded-circle position-relative">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a> */}
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                               <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-4 col-xl-6 mb-3">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 lead_card">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Mr.Raj</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-4.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" /></span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/residential_5.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Category</small>
                                                            <p className="mb-0 fw-500">Residential</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/flat_6.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Property Type</small>
                                                            <p className="mb-0 fw-500">Flat</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Location</small>
                                                            <p className="mb-0 fw-500">Devanahalli</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Source</small>
                                                            <p className="mb-0 fw-500">Google Ads</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Project</small>
                                                            <p className="mb-0 fw-500">Brigade Orchards</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Furnishing</small>
                                                            <p className="mb-0 fw-500">Fully Furnished</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Builtup Area</small>
                                                            <p className="mb-0 fw-500">1200Sq.ft</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bedrooms</small>
                                                            <p className="mb-0 fw-500">1-2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Bathrooms</small>
                                                            <p className="mb-0 fw-500">2-3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Assign To</small>
                                                            <p className="mb-0 fw-500">Arjun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Lead Priority</small>
                                                            <p className="mb-0 fw-500">High</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-6 mb-4 py-1">
                                                    <div className="d-flex align-items-start single_item">
                                                        <img src={toAbsoluteUrl('/media/custom/calendar.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                        <div className="d-flex flex-column">
                                                            <small className="text_light">Date</small>
                                                            <p className="mb-0 fw-500">30 Dec 2022</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    100
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    10
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Merge">
                                                <img src={toAbsoluteUrl('/media/custom/merge.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    41
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    26
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Register">
                                                <img src={toAbsoluteUrl('/media/custom/register.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            {/* <a className="btn btn-sm icon_bg rounded-circle position-relative">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a> */}
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                               <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {LeadPage}
