import React,{FC} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

const PropertyList: FC = () => {
    return (
        <div className="property_list">
            <div className="row">
                <div className="card-group">
                    <div className="col-sm-6 col-xxl-3 col-xl-4 mb-4">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 property_card">
                            <div className='card-body px-3 pt-3 pb-2 pb-md-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                   <div className="d-flex align-items-center">
                                        <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                    <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                        235648
                                                    </label>
                                                </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <p className="mb-0 contact_name">Brigade</p>
                                        </div>
                                        {/* <p className="mb-0 me-3">2365415</p> */}
                                   </div>
                                   <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-4 d-flex align-items-center justify-content-center mb-3">
                                            <img src={toAbsoluteUrl('/media/avatars/property_img1.jpg')} className="user_img br_5" alt='' />
                                        </div>
                                        <div className="col-xl-8">
                                            <div>
                                                {/* <h3>Brigade</h3> */}
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon me-1" alt='' />
                                                    Brigade Orchards
                                                </p>
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon me-1" alt='' />Devanahalli, Bangalore</p>
                                                <div className="d-flex">
                                                    <p className="me-2">
                                                        <img src={toAbsoluteUrl('/media/custom/residential.svg')} className="svg_icon me-1" alt='' />
                                                        Residential
                                                    </p>
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/flat.svg')} className="svg_icon me-1" alt='' />
                                                        Flat
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="accordion property_accordion" id="property_accordion1">
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />BHK 1
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />
                                                            BHK 2
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="d-flex align-items-center me-2 mb-2">
                                                    <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">Arjun</p>
                                                </div>
                                                <div className="d-flex align-items-center me-2 mb-2"> 
                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">12 Dec 2022</p>
                                                </div>
                                                <small className="mb-0 bg_secondary rounded-pill p-2 me-2 mb-2">Under Construction</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="file">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="SecContact">
                                                <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option>On Market</option>
                                                <option>Reserved</option>
                                                <option>Off Market</option>
                                                <option>Sold</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3 col-xl-4 mb-4">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 property_card">
                            <div className='card-body px-3 pt-3 pb-2 pb-md-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                   <div className="d-flex align-items-center">
                                        <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                    <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                        235648
                                                    </label>
                                                </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <p className="mb-0 contact_name">Brigade</p>
                                        </div>
                                        {/* <p className="mb-0 me-3">2365415</p> */}
                                   </div>
                                   <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-4 d-flex align-items-center justify-content-center mb-3">
                                            <img src={toAbsoluteUrl('/media/avatars/property_img1.jpg')} className="user_img br_5" alt='' />
                                        </div>
                                        <div className="col-xl-8">
                                            <div>
                                                {/* <h3>Brigade</h3> */}
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon me-1" alt='' />
                                                    Brigade Orchards
                                                </p>
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon me-1" alt='' />Devanahalli, Bangalore</p>
                                                <div className="d-flex">
                                                    <p className="me-2">
                                                        <img src={toAbsoluteUrl('/media/custom/residential.svg')} className="svg_icon me-1" alt='' />
                                                        Residential
                                                    </p>
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/flat.svg')} className="svg_icon me-1" alt='' />
                                                        Flat
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="accordion property_accordion" id="property_accordion1">
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />BHK 1
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />
                                                            BHK 2
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="d-flex align-items-center me-2 mb-2">
                                                    <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">Arjun</p>
                                                </div>
                                                <div className="d-flex align-items-center me-2 mb-2"> 
                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">12 Dec 2022</p>
                                                </div>
                                                <small className="mb-0 bg_secondary rounded-pill p-2 me-2 mb-2">Under Construction</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="file">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="SecContact">
                                                <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option>On Market</option>
                                                <option>Reserved</option>
                                                <option>Off Market</option>
                                                <option>Sold</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3 col-xl-4 mb-4">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 property_card">
                            <div className='card-body px-3 pt-3 pb-2 pb-md-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                   <div className="d-flex align-items-center">
                                        <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                    <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                        235648
                                                    </label>
                                                </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <p className="mb-0 contact_name">Brigade</p>
                                        </div>
                                        {/* <p className="mb-0 me-3">2365415</p> */}
                                   </div>
                                   <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-4 d-flex align-items-center justify-content-center mb-3">
                                            <img src={toAbsoluteUrl('/media/avatars/property_img1.jpg')} className="user_img br_5" alt='' />
                                        </div>
                                        <div className="col-xl-8">
                                            <div>
                                                {/* <h3>Brigade</h3> */}
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon me-1" alt='' />
                                                    Brigade Orchards
                                                </p>
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon me-1" alt='' />Devanahalli, Bangalore</p>
                                                <div className="d-flex">
                                                    <p className="me-2">
                                                        <img src={toAbsoluteUrl('/media/custom/residential.svg')} className="svg_icon me-1" alt='' />
                                                        Residential
                                                    </p>
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/flat.svg')} className="svg_icon me-1" alt='' />
                                                        Flat
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="accordion property_accordion" id="property_accordion1">
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />BHK 1
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />
                                                            BHK 2
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="d-flex align-items-center me-2 mb-2">
                                                    <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">Arjun</p>
                                                </div>
                                                <div className="d-flex align-items-center me-2 mb-2"> 
                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">12 Dec 2022</p>
                                                </div>
                                                <small className="mb-0 bg_secondary rounded-pill p-2 me-2 mb-2">Under Construction</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="file">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="SecContact">
                                                <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option>On Market</option>
                                                <option>Reserved</option>
                                                <option>Off Market</option>
                                                <option>Sold</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3 col-xl-4 mb-4">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1 property_card">
                            <div className='card-body px-3 pt-3 pb-2 pb-md-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                   <div className="d-flex align-items-center">
                                        <form action="">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                    <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                        235648
                                                    </label>
                                                </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <p className="mb-0 contact_name">Brigade</p>
                                        </div>
                                        {/* <p className="mb-0 me-3">2365415</p> */}
                                   </div>
                                   <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-4 d-flex align-items-center justify-content-center mb-3">
                                            <img src={toAbsoluteUrl('/media/avatars/property_img1.jpg')} className="user_img br_5" alt='' />
                                        </div>
                                        <div className="col-xl-8">
                                            <div>
                                                {/* <h3>Brigade</h3> */}
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon me-1" alt='' />
                                                    Brigade Orchards
                                                </p>
                                                <p>
                                                    <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon me-1" alt='' />Devanahalli, Bangalore</p>
                                                <div className="d-flex">
                                                    <p className="me-2">
                                                        <img src={toAbsoluteUrl('/media/custom/residential.svg')} className="svg_icon me-1" alt='' />
                                                        Residential
                                                    </p>
                                                    <p>
                                                        <img src={toAbsoluteUrl('/media/custom/flat.svg')} className="svg_icon me-1" alt='' />
                                                        Flat
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="accordion property_accordion" id="property_accordion1">
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingOne">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />BHK 1
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item px-xxl-4">
                                                    <p className="accordion-header d-flex justify-content-between" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                                        <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon me-1" alt='' />
                                                            BHK 2
                                                        </button>
                                                        <p className='mb-0'>645-582 Sqft</p>
                                                        <p className='mb-0'>50-60L</p>
                                                    </p>
                                                    <div id="collapseTwo2" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#property_accordion1">
                                                        <div className="accordion-body px-0 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <p className='mb-0'>2BHK + 2T</p>
                                                                <p className='mb-0'>1004 sqft</p>
                                                                <p className='mb-0'>₹71.2 L</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="d-flex align-items-center me-2 mb-2">
                                                    <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">Arjun</p>
                                                </div>
                                                <div className="d-flex align-items-center me-2 mb-2"> 
                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="icon me-2"/>
                                                    <p className="ml-2 mb-0 fixed_text">12 Dec 2022</p>
                                                </div>
                                                <small className="mb-0 bg_secondary rounded-pill p-2 me-2 mb-2">Under Construction</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="file">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="SecContact">
                                                <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option>On Market</option>
                                                <option>Reserved</option>
                                                <option>Off Market</option>
                                                <option>Sold</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {PropertyList}
