// import React,{FC} from 'react'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'


// const Reports: FC = () => {
//     return(
//         <div className="d-flex flex-column flex-center">   
//             <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
//             <h1>Under Construction</h1>
//         </div>
//     )
// }

// export {Reports}

import React,{FC, useState} from 'react'
import { CallLead } from './callLead';
import { FavoriteLead } from './favoriteLead';
import { FinanceLead } from './financeLead';
import { HrLead } from './hrLead';
import { ProjectLead } from './projectReport';
import { ReportsLead } from './reportsLead';
import { TaskLead } from './taskLead';
import { TransactionLead } from './transactionLead';



const Reports: FC = () => {
    
    const [toggle, setToggle] = useState(false);

    const handleHideData = () => {
        setToggle(!toggle);
    };

    return(
        <div className="reports_tab_page bg_white h-100 p-4">
            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="favorite-tab" data-bs-toggle="pill" data-bs-target="#favorite" type="button" role="tab" aria-controls="favorite" aria-selected="true">Favorite</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="lead-tab" data-bs-toggle="pill" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="false">Lead</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="transaction-tab" data-bs-toggle="pill" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="false">Transaction</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="call-tab" data-bs-toggle="pill" data-bs-target="#call" type="button" role="tab" aria-controls="call" aria-selected="false">Call</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="project-tab" data-bs-toggle="pill" data-bs-target="#project" type="button" role="tab" aria-controls="project" aria-selected="false">Project</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="task-tab" data-bs-toggle="pill" data-bs-target="#task" type="button" role="tab" aria-controls="task" aria-selected="false">Task</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="finance-tab" data-bs-toggle="pill" data-bs-target="#finance" type="button" role="tab" aria-controls="finance" aria-selected="false">Finance</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="hr-tab" data-bs-toggle="pill" data-bs-target="#hr" type="button" role="tab" aria-controls="hr" aria-selected="false">HR</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="favorite" role="tabpanel" aria-labelledby="favorite-tab">
                  <FavoriteLead/>
                </div>
                <div className="tab-pane fade" id="lead" role="tabpanel" aria-labelledby="lead-tab">
                    <ReportsLead/>
                </div>
                <div className="tab-pane fade" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                    <TransactionLead/>
                </div>
                <div className="tab-pane fade" id="call" role="tabpanel" aria-labelledby="call-tab">
                    <CallLead/>
                </div>
                <div className="tab-pane fade" id="project" role="tabpanel" aria-labelledby="project-tab">
                    <ProjectLead/>
                </div>
                <div className="tab-pane fade" id="task" role="tabpanel" aria-labelledby="task-tab">
                    <TaskLead/>
                </div>
                <div className="tab-pane fade" id="finance" role="tabpanel" aria-labelledby="finance-tab">
                    <FinanceLead/>
                </div>
                <div className="tab-pane fade" id="hr" role="tabpanel" aria-labelledby="hr-tab">
                    <HrLead/>
                </div>
            </div>
        </div>
    )
}
export {Reports}