import React,{FC} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
    'CCTV',
    'Play area',
    'Gym',
    'Swimming Pool',
    'Walking Track',
    'Internet',
  ];
  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const LeadForm:  FC = () => {

    const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

    return(
        <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header w-100' id='kt_contact_header'>
          <h3 className='card-title fw-bolder text-dark'>Add Lead</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_contact_close'
            >
                <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
            </button>
          </div>
        </div>
        <div className='card-body position-relative' id='kt_contact_body'>
        
        <div className="accordion" id="leadAccordion"> 
            <form action="">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="leadheadingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Basic Details
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="leadheadingOne" data-bs-parent="#leadAccordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Contact Person*</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="" aria-label="Search a contact"/>
                                        <button className="btn btn-outline-secondary" type="button">
                                        <i className="fa fa-plus-circle text_primary"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Looking for*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Buy</option>
                                            <option>Rent</option>
                                        </select>
                                    </div>  
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Property Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Apartment</option>
                                            <option>Villa</option>
                                            <option>Independent House</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Location</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Chennai</option>
                                            <option>Kerala</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Lead Source</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select any one</option>
                                            <option>Google Ads</option>
                                            <option>Cold Call</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Lead Group</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>New contact</option>
                                            <option>New contact</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Rating</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Opportunity Value</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="px-2 py-2 btn_secondary bg_secondary btn btn-sm prepend">
                                            <option>₹</option>
                                            <option>$</option>
                                        </select>
                                        <input type="text" className="form-control" placeholder="0"/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Status</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Status</option>
                                            <option>New lead</option>
                                            <option>Contacted</option>
                                            <option>Option Sent</option>
                                            <option>Site Visit Scheduled</option>
                                            <option>Inspected</option>
                                            <option>Shortlisted</option>
                                            <option>On Hold</option>
                                            <option>Lost</option>
                                            <option>Won</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Assign To</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Dennis</option>
                                            <option>Rajan</option>
                                        </select>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="leadheadingtwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">
                        Requirment
                        </button>
                    </h2>
                    <div id="collapsetwo" className="accordion-collapse collapse" aria-labelledby="leadheadingtwo" data-bs-parent="#leadAccordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Bedrooms</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="text" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="text" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Bathrooms</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Price Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option>₹</option>
                                                    <option>$</option>
                                                </select>
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                    <option>₹</option>
                                                    <option>$</option>
                                                </select>
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Built Area Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Plot Area Range</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                                <span className="input-group-text">Sqft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-3">
                                    <label htmlFor="basic-url" className="form-label">No. of Parking</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Timeline for closure</label>
                                    <div className="input-group mb-3">
                                        <input type="date" className="form-control" placeholder="date"/> 
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="basic-url" className="form-label">Amenities</label>
                                    <FormControl sx={{ m: 0, width: 300, mt: 0 }}>
                                        <Select
                                            multiple
                                            displayEmpty
                                            value={personName}
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                return <p>Amenities</p>;
                                                }

                                                return selected.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                            <MenuItem disabled value="">
                                                <em>Amenities</em>
                                            </MenuItem>
                                            {names.map((name) => (
                                                <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, personName, theme)}
                                                >
                                                {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Furnishing Status</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select</option>
                                            <option>Fully Finished</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label className="form-label">Posession Status</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select</option>
                                            <option>Ready to Move in</option>
                                            <option>Under Construction</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-12 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Remarks</label>
                                    <textarea
                                        className='form-control border-0 p-2 resize-none min-h-25px br_10'
                                        data-kt-autosize='true'
                                        rows={4}
                                        placeholder='Remarks'
                                    >
                                    </textarea>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </div>
        <div className='card-footer py-5 text-center' id='kt_contact_footer'>
            <Link to='/dashboard' className='btn btn_primary text-primary'>
            Save
            <KTSVG
                path='/media/custom/save_white.svg'
                className='svg-icon-3 svg-icon-primary ms-2'
            />
            {/* <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 svg-icon-primary'
            /> */}
            </Link>
        </div>  
      </div>
    )
}


export {LeadForm}