import React,{FC} from 'react'
import DataTable from 'react-data-table-component';


// Table data
const columns = [
    {
        name: 'Profile Name',
        selector: (row: { profileName: any; }) => row.profileName,
        sortable: true,
    },
    {
        name: 'Created Date',
        selector: (row: { createdDate: any; }) => row.createdDate,
        sortable: true,
    },
    {
        name: 'Modified Date',
        selector: (row: { modifiedDate: any; }) => row.modifiedDate,
        sortable: true,
    },
];

const data = [
    {
        profileName: 'Administrator',
        createdDate:'20/05/2022',
        modifiedDate:'21/05/2022',
    },
    {
        profileName: 'Team Leader',
        createdDate:'20/05/2022',
        modifiedDate:'21/05/2022',
    },
    {
        profileName: 'User',
        createdDate:'20/05/2022',
        modifiedDate:'21/05/2022',
    },
]

// Table data

const RoleProfile: FC = () => {
    return(
        <section className="role_profile">
            <div className="row">
                <div className="card-group">
                    <div className="col-xl-6 mb-4">
                        <div className="card h-100 bs_1 mx-3">
                            <div className="card-header">
                                <h4>Profile Setting</h4>
                                <button className="btn btn-outline-secondary" type="button">
                                    <i className="fa fa-plus-circle text_primary"></i>
                                </button>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    // pagination
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 mb-4">
                        <div className="card h-100 bs_1 mx-3">
                            <div className="card-header">
                                <h4>Role Setting</h4>
                            </div>
                            <div className="card-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label className="form-label">Search</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Enter to Search" aria-label="Search"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingOne">
                                                        <button className="accordion-button p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Contact
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingTwo">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Lead
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault2"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingThree">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            Project
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault3"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault3"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingFour">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            Transactions
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault4"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault4"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingFive">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            Task
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault5"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault5"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingSix">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            File
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault6"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault6"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingSeven">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                            Finance
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault7"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault7"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Own Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Hidden Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Read Only Fields-Other Records</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Contact Type</option>
                                                                                <option>Source</option>
                                                                                <option>Assigned To</option>
                                                                                <option>Created Date</option>
                                                                                <option>Email</option>
                                                                                <option>Phone Number</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingEight">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                            Message
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault8"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault8"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header d-flex flex-center" id="headingNine">
                                                        <button className="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                            Reports
                                                        </button>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault9"/>
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault9"></label>
                                                        </div>
                                                    </h2>
                                                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className="row">
                                                                <div className="col-12 d-md-flex justify-content-between">
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">View*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Edit*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Delete*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="basic-url" className="form-label">Archive*</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>All</option>
                                                                                <option>Team</option>
                                                                                <option>Own</option>
                                                                                <option>None</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group mb-4">
                                                                        <label htmlFor="basic-url" className="form-label">Permission</label>
                                                                        <div className="input-group mb-3 input_prepend">
                                                                            <select className="btn_secondary btn btn-sm w-100">
                                                                                <option>Assign</option>
                                                                                <option>Bulk Edit</option>
                                                                                <option>Create</option>
                                                                                <option>Import</option>
                                                                                <option>Export</option>
                                                                                <option>Notify</option>
                                                                            </select>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export {RoleProfile}