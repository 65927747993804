import React,{FC} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const LeadFilter:  FC = () => {
    return(
        <div className='card shadow-none rounded-0 w-100'>
            <form action="">
                <div className='card-header w-100' id='kt_filter_header'>
                    <h3 className='card-title fw-bolder text-dark'>Lead Filter</h3>

                    <div className='card-toolbar'>
                        <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                        id='kt_filter_close'
                        >
                            <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                        {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                        </button>
                    </div>
                </div>
                <div className='card-body position-relative' id='kt_filter_body'>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Looking For</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Lead Type</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Location</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Lead Source</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Lead Group</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Opportunity</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Assign To</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Bedroom</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Bathroom</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Price Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Builtup Area Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Plot Area Range</label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Min"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Max"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Features</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Branch</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div> 
                        <div className="col-md-3 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created by</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <label htmlFor="basic-url" className="form-label">Updated by</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div> 
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status Changed by</label>
                            <div className="input-group mb-3">
                                <select className="btn_secondary btn btn-sm w-100">
                                    <option>All</option>
                                    <option>All</option>
                                </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Created Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Updated Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Last Note Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Status Changed Date</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer py-5 text-center filter_footer d-flex flex-column flex-md-row align-items-center justify-content-between' id='kt_filter_footer'>
                    <div className="mb-3 mb-md-0">
                        <div className="form-check d-flex">
                            <input className="form-check-input" type="checkbox" value="" id="filterCheck"/>
                            <label className="form-check-label mx-3" htmlFor="filterCheck">
                                Include Archived Records
                            </label>
                        </div>
                    </div>
                    <div className="mb-3 mb-md-0">
                        <button className='btn btn-sm btn_soft_primary save_btn p-3 pr-0 mx-3 br_10' data-bs-toggle="tooltip" data-bs-placement="top" title="Save">
                            <KTSVG
                                path='/media/custom/save_orange.svg'
                                className='svg-icon-3 svg-icon-primary me-0'
                            />
                            {/* <i className="far fa-save mx-auto"></i> */}
                        </button>
                        <button className='btn btn-sm reset_btn mx-3 br_10'>
                            <i className="fas fa-undo"></i>
                            Reset
                        </button>
                        <button className='btn btn-sm btn_primary mx-3 br_10'>
                            <KTSVG path='/media/custom/search_white.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                            {/* <i className="fas fa-search text-white"></i> */}
                            Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}


export {LeadFilter}