import React,{FC} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const ProjectForm:  FC = () => {
    return(
    <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header w-100' id='kt_contact_header'>
          <h3 className='card-title fw-bolder text-dark'>Add Project</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_contact_close'
            >
                <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
            </button>
          </div>
        </div>
    <div className='card-body position-relative' id='kt_contact_body'>
        <form action="">
            <div className="accordion" id="prop_accordion"> 
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Basic Details
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Contact Person*</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="" aria-label="Search a contact"/>
                                        <button className="btn btn-outline-secondary" type="button">
                                        <i className="fa fa-plus-circle text_primary"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Available for*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Sale</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Commision*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="0"/>
                                            <span className="input-group-text">%</span>
                                    </div>
                                </div>  
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Property Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Apartment</option>
                                            <option>Villa</option>
                                            <option>Row House</option>
                                            <option>Independent House</option>
                                            <option>Farm Plot</option>
                                            <option>Commercial Shop</option>
                                            <option>Office Space</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Property Status*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>Under Construction</option>
                                            <option>Ready to Move-in</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Property Source*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select any one</option>
                                            <option>Sourcing Person</option>
                                            <option>Website</option>
                                            <option>Database</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Status*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select</option>
                                            <option>On Market</option>
                                            <option>Reserved</option>
                                            <option>Off Market</option>
                                            <option>Sold</option>
                                        </select>
                                    </div> 
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Assign To*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Dennis</option>
                                            <option>Rajan</option>
                                        </select>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingtwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two" aria-expanded="false" aria-controls="two">
                        Project Address
                        </button>
                    </h2>
                    <div id="two" className="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Address line 1</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="House no"/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Address line 2</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Street address"/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Locality*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option>India</option>
                                            <option>United Kingdom</option>
                                            <option>Canada</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">City*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select City</option>
                                            <option>Chennai</option>
                                            <option>Trivandrum</option>
                                            <option>Bangalore</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">State*</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select State</option>
                                            <option>Tamilnadu</option>
                                            <option>Maharastra</option>
                                            <option>Kerala</option>
                                        </select>
                                    </div>  
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Zip Code</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Zip Code"/>
                                    </div>
                                </div> 
                              
                               
                                <div className="col-md-6 col-12 mb-3">
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="input-group mb-3 input_prepend mx-1">
                                                <input type="text" className="form-control" placeholder="Latitude"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="input-group mb-3 input_prepend mx-1">
                                                <input type="text" className="form-control" placeholder="Longitude"/>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                        <div className="input-group mb-3 input_prepend mx-1">
                                            <input type="text" className="form-control" placeholder="Google pin link"/>
                                        </div>
                                    {/* <button className='btn btn_primary btn-block'>Generate Latitude/Longitude</button> */}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingthree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three" aria-expanded="false" aria-controls="three">
                            Project Features
                        </button>
                    </h2>
                    <div id="three" className="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Stage</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Stage</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Age of Project</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Age</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Furnishing</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Furnishing</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div>  
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Builtup Area</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Plot Area</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Tower</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Tower</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <label htmlFor="basic-url" className="form-label">Specifications</label>
                                            <div className="input-group first mb-3 input_prepend">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option value="default" disabled>Select</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="basic-url" className="form-label">Units</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Units Size</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Min"/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group first mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Max"/>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Ownership Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Ownership</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">No of Balcony</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="number" className="form-control" placeholder=""/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Project Facing</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Facing</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Kitchen Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Kitchen</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Flooring</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Flooring</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Vaasthu</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Vasthu</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Currenty under Loan</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Vasthu</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Available From</label>
                                    <div className="input-group mb-3">
                                        <input type="date" className="form-control" placeholder="date"/> 
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Site Visit Preference</label>
                                    <div className="input-group mb-3">
                                        <input type="date" className="form-control" placeholder="date"/> 
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">No.of Car Park</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder=""/>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Car Park Type</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Car park Type</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Water Supply</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Water Supply</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Gated Community</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Gated Community</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Amenities</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select Amenities</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Property Highlight</label>
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Enter Property Highlight"/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">RERA Registered</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Select RERA Registered</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Enter RERA Number</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Enter RERA Number</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Completion Certificate</label>
                                    <div className="input-group mb-3 input_prepend">
                                        <select className="btn_secondary btn btn-sm w-100">
                                            <option value="default" disabled>Completion Certificate</option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="card property_units_card mb-4">
                                    <div className="card-body p-3 p-md-7">
                                        <div className="row">
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Units Type</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn_secondary btn btn-sm w-100">
                                                        <option value="default" disabled>Enter RERA Number</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Total No.of Units</label>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="input-group first mb-3 input_prepend">
                                                            <input type="number" className="form-control" placeholder="Min"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group first mb-3 input_prepend">
                                                            <input type="number" className="form-control" placeholder="Max"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Units</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn_secondary btn btn-sm w-100">
                                                        <option value="default" disabled>Flat</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Local Currency</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <select className="btn_secondary btn btn-sm w-100">
                                                        <option value="default" disabled>Flat</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                {/* <div> */}
                                                    <span className="btn btn-file d-flex flex-md-row p-2 p-md-4">
                                                        <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Floor Plan <input type="file"/>
                                                    </span>
                                                {/* </div> */}
                                                <button className="btn btn_soft_primary">
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
                        Unit,Price & Area
                        </button>
                    </h2>
                    <div id="four" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Sale Price*</label>
                                    <div className="input-group first mb-3 input_prepend">
                                        <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                            <option>₹</option>
                                            <option>$</option>
                                        </select>
                                        <input type="text" className="form-control" placeholder=""/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Plot Area*</label>
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Parkings"/>
                                        <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                            <option>Sqft</option>
                                            <option>ft</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Built Area*</label>
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Energy Rating(Consumption)"/>
                                        <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                            <option>Sqft</option>
                                            <option>ft</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five" aria-expanded="false" aria-controls="five">
                             Description
                        </button>
                    </h2>
                    <div id="five" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-12 col-12 mb-3">
                                    <label htmlFor="basic-url" className="form-label">Description</label>
                                    <textarea
                                        className='form-control border-0 p-2 resize-none min-h-25px br_10'
                                        data-kt-autosize='true'
                                        rows={7}
                                        placeholder='Description'
                                    ></textarea>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six" aria-expanded="false" aria-controls="six">
                       Project Images,Floor Plan & Documents
                        </button>
                    </h2>
                    <div id="six" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#prop_accordion">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className="btn btn-file d-flex flex-md-row p-2 p-md-4">
                                        <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Documents <input type="file"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </div>
        <div className='card-footer py-5 text-center' id='kt_contact_footer'>
            <Link to='/dashboard' className='btn btn_primary text-primary'>
            Save
            <KTSVG
                path='/media/custom/save_white.svg'
                className='svg-icon-3 svg-icon-primary ms-2'
              />
            </Link>
        </div>  
    </div>
    )
}


export {ProjectForm}
