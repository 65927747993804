import React,{FC} from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';


const TransactionList: FC = () => {
    return(
        <div className="transaction_page">
            <div className="row">
                <div className="card-group">
                    <div className="col-xl-6 mb-4">
                        <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                            <div className='card-body px-3 pt-3 pb-0'>
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <div className="d-flex align-items-center">
                                        <form action="">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                    235648
                                                </label>
                                            </div>
                                        </form>
                                        <div className="ms-3 ml-2 d-flex align-items-center">
                                            <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                            <p className="mb-0 contact_name">Name</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                        {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                        <div className="btn-group">
                                            <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0">
                                        <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-2"/>
                                            <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                        </div>
                                        <div className="d-xxl-flex justify-content-between">
                                            <div className="d-flex align-items-center mb-2">
                                                <p className="mb-0 d-flex flex-wrap">
                                                    <span>
                                                        <img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-2" />
                                                    </span>
                                                        8965896547
                                                </p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div className='mb-3 mt-6 transaction_details'>
                                    <div className="row">
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Developer Name</small>
                                                    <p className="mb-0 fw-500">Brigade group</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Locality</small>
                                                    <p className="mb-0 fw-500">Devanahalli</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/project_type.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Project Type</small>
                                                    <p className="mb-0 fw-500">Apartment</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">BuiltUp Area</small>
                                                    <p className="mb-0 fw-500">1200Sq.ft</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/floor.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Floor</small>
                                                    <p className="mb-0 fw-500">Floor-11</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/project_name.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Project Name</small>
                                                    <p className="mb-0 fw-500">Brigade Orchards</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/location_10.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">City</small>
                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Configuration</small>
                                                    <p className="mb-0 fw-500">2 BHK</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/bhk_4.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">UDS</small>
                                                    <p className="mb-0 fw-500">200 Sq.ft</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/lead_1.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Tower</small>
                                                    <p className="mb-0 fw-500">Tower 2</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Sale Value</small>
                                                    <p className="mb-0 fw-500">INR 90L</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Opportunity Value</small>
                                                    <p className="mb-0 fw-500">INR 1.2L</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Amount Collected</small>
                                                    <p className="mb-0 fw-500">INR 1L</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Amount Due</small>
                                                    <p className="mb-0 fw-500">INR 80K</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/budget_3.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Cashback</small>
                                                    <p className="mb-0 fw-500">INR 10K</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/source_2.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Sources</small>
                                                    <p className="mb-0 fw-500">Google Ads</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/assign_9.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Deal Closed By</small>
                                                    <p className="mb-0 fw-500">Arjun</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Lead Booked Date</small>
                                                    <p className="mb-0 fw-500">05 Apr 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Lead Created Date</small>
                                                    <p className="mb-0 fw-500">12 Dec 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-2 col-sm-4 col-6 mb-4 py-1">
                                            <div className="d-flex align-items-center single_item">
                                                <img src={toAbsoluteUrl('/media/custom/lead/created_8.svg')} className="svg_icon mb-1 me-2" alt='' />
                                                <div className="d-flex flex-column">
                                                    <small className="text_light">Lead Reg Date</small>
                                                    <p className="mb-0 fw-500">08 July 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0 p-1">
                                    <div className="row">
                                        <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    16
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    88
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    5
                                                </span>
                                            </a>
                                            <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_bg rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Whatsapp">
                                                <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/>
                                                <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                    13
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                            <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                <option selected>Status</option>
                                                <option value="1">Pending</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {TransactionList}