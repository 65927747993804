import React,{FC} from 'react'
import ReactDOM from 'react-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import DataTable from 'react-data-table-component'; 

import { DataGrid,
     GridColDef,
    //   GridColumnHeaderParams,
       GridRenderCellParams,
        // GridValueGetterParams
     } from '@mui/x-data-grid';



const Contactcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70,headerClassName: 'dg_header' },
    { field: 'firstName', headerName: 'First name', width: 130,headerClassName: 'dg_header' },
    { field: 'Email', headerName: 'Email', width: 130,headerClassName: 'dg_header' },
    { field: 'PhoneNumber', headerName: 'Phone Number', type: 'number', width: 130,headerClassName: 'dg_header'},
    { field: 'designation', headerName: 'Designation', width: 130,headerClassName: 'dg_header' },
    { field: 'createdBy', headerName: 'Created By', width: 130,headerClassName: 'dg_header' },
    { field: 'createdOn', headerName: 'Created On', width: 130,headerClassName: 'dg_header' },
  ];

const Contactrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    designation:'Asst.Manager',
    createdBy:'Kumar',
    createdOn: '10 Dec 2022'
  },
];


const leadcolumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 ,headerClassName: 'dg_header'},
    { field: 'firstName', headerName: 'First name', width: 130, headerClassName: 'dg_header',
        renderCell: (params: GridRenderCellParams<Date>) => (
        <strong>
          <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="table_icon me-2" />
          
        </strong>
      ),
    },
    { field: 'Email', headerName: 'Email', width: 130, headerClassName: 'dg_header' },
    { field: 'PhoneNumber', headerName: 'Phone Number', type: 'number', width: 130, headerClassName: 'dg_header'},
    { field: 'Project', headerName: 'Project', width: 130, headerClassName: 'dg_header'},
    { field: 'Configuration', headerName: 'Configuration', width: 130, headerClassName: 'dg_header'},
    { field: 'ProjectType', headerName: 'Project Type', width: 130, headerClassName: 'dg_header'},
    { field: 'Source', headerName: 'Source', width: 130, headerClassName: 'dg_header'},
    { field: 'CreatedOn', headerName: 'Created on', width: 130, headerClassName: 'dg_header'},
    { field: 'AssignTo', headerName: 'Assign To', width: 130, headerClassName: 'dg_header'},
    { field: 'Budget', headerName: 'Budget', width: 130, headerClassName: 'dg_header'},
    { field:'Action', renderCell: () => (
        <select className="form-select toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
            <option selected>Status</option>
            <option value="1">Pending</option>
            <option value="2">Completed</option>
        </select>
      ),
    }
  ];

const leadrows = [
  { id: '85213',
    firstName: 'Mr.Raj',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project:'Sobha forest Edge',
    Configuration:'2BHK',
    ProjectType:'Villa',
    Source:'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
  { id: '65236',
    firstName: 'Mr.Ram',
    Email:'raj2002@gmail.com',
    PhoneNumber: '8965745689',
    Project:'Sobha forest Edge',
    Configuration:'2BHK',
    ProjectType:'Villa',
    Source:'Facebook',
    CreatedOn: '10 Dec 2022',
    AssignTo: 'Ram Kumar',   
    Budget: 'INR 3c To 4c',   
  },
];




const TaskDetails: FC = () => {
    return(
        <div className="w-100 contact_details_page full_screen">
            <div className="card main_bg h-100">
                <div className="card-header d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-sm-3 col-12">
                            <div className='card-toolbar'>
                                <button className="btn mx-3 expand_btn">
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/expand_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button className="btn mx-3 minimize_btn">
                                    <img src={toAbsoluteUrl('/media/custom/overview-icons/minimize_white.svg')} className="svg_icon" alt='' />
                                </button>
                                <button
                                    type='button'
                                    className='btn  me-n5 mx-3 close_btn'
                                    id='kt_expand_close'
                                    >
                                        <img src={toAbsoluteUrl('/media/custom/overview-icons/cancel_white.svg')} className="svg_icon" alt='' />
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-9 col-12 d-flex align-items-center justify-content-end">
                            <div className="input-group search_btn me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn bg_primary" type="button">
                                        <img src={toAbsoluteUrl('/media/custom/search_white.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                            <a className="me-4 btn btn-sm me-4 btn_primary" id='kt_contact_toggle'>Add+</a>
                            <select className="form-select toggle_white me-4 btn btn-sm btn_primary" name="action" id="action">
                                <option value="action">Action</option>
                                <option value="add">Add</option>
                                <option value="inport">Import</option>
                                <option value="edit">Edit</option>
                            </select>
                        </div>
                    </div>
                    {/* <h3>Contact Full screen</h3> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 name_card h-100 mx-2">
                                    <div className="card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex">
                                                            <p className="mb-0">789658</p>
                                                            <h4 className="mb-0 ms-2">Mr.Raj</h4>
                                                        </div>
                                                        <p className="mb-0">Brigade</p>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <a href="/#" className="btn_soft_primary"><i className="fas fa-envelope"></i></a>
                                                        <a href="/#" className="btn_soft_primary"><i className="fas fa-phone-alt"></i></a>
                                                        <a href="/#" className="btn_soft_primary"><i className="fas fa-clipboard-list"></i></a>
                                                        <a href="/#" className="btn_soft_primary">
                                                            <img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt='' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mb-3">
                                <div className="card bs_1 h-100 mx-2 info_card">
                                    <div className="card-body p-3">
                                        <div className="mt-xxl-3">
                                            <small className="mb-0">Email</small>
                                            <p className="mb-0">raj2002@gmail.com</p>
                                        </div>
                                        <div className="mt-xxl-3">
                                            <small className="mb-0">Phone Number</small>
                                            <p className="mb-0">9856895689</p>
                                        </div>
                                        <div className="mt-xxl-3">
                                            <small className="mb-0">Contact Category</small>
                                            <p className="mb-0">Buyer</p>
                                        </div>
                                        <div className="mt-xxl-3">
                                            <small className="mb-0">Created By</small>
                                            <p className="mb-0">Kumar</p>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_container bg_white br_10 bs_1">
                        <div className="row mt-4">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="notes-tab" data-bs-toggle="pill" data-bs-target="#notes" type="button" role="tab" aria-controls="notes" aria-selected="false">Notes</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="files-tab" data-bs-toggle="pill" data-bs-target="#files" type="button" role="tab" aria-controls="files" aria-selected="false">Files</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="message-tab" data-bs-toggle="pill" data-bs-target="#message" type="button" role="tab" aria-controls="message" aria-selected="false">Messages</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="contact-tab" data-bs-toggle="pill" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Sec.contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="lead-tab" data-bs-toggle="pill" data-bs-target="#lead" type="button" role="tab" aria-controls="lead" aria-selected="false">Lead</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="duplicate-tab" data-bs-toggle="pill" data-bs-target="#duplicate" type="button" role="tab" aria-controls="duplicate" aria-selected="false">Duplicate</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tasks-tab" data-bs-toggle="pill" data-bs-target="#tasks" type="button" role="tab" aria-controls="tasks" aria-selected="false">Tasks</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="timeline-tab" data-bs-toggle="pill" data-bs-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="false">Active timeline</button>
                                    </li>
                                </ul>
                                <div className="tab-content pt-5" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <div className="row">
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Email</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Enter Email"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Phone Number</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Enter Number"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Locality</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Locality"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">City</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="City"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Contact Category</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="City"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Source</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Source"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Created By</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Source"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Created Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" className="form-control" placeholder="date"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Company Name</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Source"/>
                                                </div>
                                            </div>  
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Assign To*</label>
                                                <div className="input-group mb-3 input_prepend py-1">
                                                    <select className="btn btn-sm w-100 text-start">
                                                        <option value="default" disabled>Dennis</option>
                                                        <option>Rajan</option>
                                                    </select>
                                                </div> 
                                            </div>  
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Updated Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" className="form-control" placeholder="date"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Status Changed Date</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" className="form-control" placeholder="date"/> 
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">National ID</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder="Enter ID"/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">DOB</label>
                                                <div className="input-group mb-3">
                                                    <input type="date" className="form-control" placeholder="date"/> 
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Nationality</label>
                                                <div className="input-group mb-3 input_prepend">
                                                    <input type="text" className="form-control" placeholder=""/>
                                                </div>
                                            </div> 
                                            <div className="col-md-6 col-xxl-4 col-12 mb-3">
                                                <label htmlFor="basic-url" className="form-label">Status</label>
                                                <div className="input-group mb-3 input_prepend py-1">
                                                    <select className="btn btn-sm w-100 text-start">
                                                        <option value="default" disabled>Verified</option>
                                                        <option>Pending</option>
                                                    </select>
                                                </div> 
                                            </div>  
                                            <div className="col-12 d-flex justify-content-center mb-4">
                                                <button className="btn btn_primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                                        Notes Tab
                                    </div>
                                    <div className="tab-pane fade" id="files" role="tabpanel" aria-labelledby="files-tab">
                                        Files
                                    </div>
                                    <div className="tab-pane fade" id="message" role="tabpanel" aria-labelledby="message-tab">
                                        {/* <h3>Messages</h3> */}
                                        <ul className="nav nav-pills mb-3 message_tabs" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-mail-tab" data-bs-toggle="pill" data-bs-target="#pills-mail" type="button" role="tab" aria-controls="pills-mail" aria-selected="true">Email</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-whatsapp-tab" data-bs-toggle="pill" data-bs-target="#pills-whatsapp" type="button" role="tab" aria-controls="pills-whatsapp" aria-selected="false">Whatsapp</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-sms" type="button" role="tab" aria-controls="pills-sms" aria-selected="false">Sms</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-calls-tab" data-bs-toggle="pill" data-bs-target="#pills-calls" type="button" role="tab" aria-controls="pills-calls" aria-selected="false">Calls</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-mail" role="tabpanel" aria-labelledby="pills-mail-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="mail_icon"><i className="fas fa-envelope"></i></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                    <div className="w-100 d-flex flex-wrap mt-2">
                                                                        <a href="" className="d-flex mail_format me-2 mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.wrd</p>
                                                                        </a> 
                                                                        <a href="" className="d-flex mail_format mb-1">
                                                                            <img src={toAbsoluteUrl('/media/technology-logos/Html5.png')} className="mail_format" alt='' />
                                                                            <p className="mb-0">Homeoptions.pdf</p>
                                                                        </a> 
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-whatsapp" role="tabpanel" aria-labelledby="pills-whatsapp-tab">
                                                <div className="mt-4">
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bs_1 mb-4">
                                                        <div className="card-body p-2">
                                                            <div className="row">
                                                                <div className="col-lg-1  d-flex justify-content-lg-center my-2">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 my-2">
                                                                    <span className="icon_bg"><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} className="svg_icon" alt=''/></span>
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        <p>Raj2020@gmail.com</p>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 my-2">
                                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in nisi vitae ipsum semper lacinia.</p>
                                                                </div>
                                                                <div className="col-lg-2 d-flex flex-wrap my-2">
                                                                    <p className="pe-3">4.00pm</p>
                                                                    <p>27/04/2022</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-sms" role="tabpanel" aria-labelledby="pills-sms-tab">SMS</div>
                                            <div className="tab-pane fade" id="pills-calls" role="tabpanel" aria-labelledby="pills-calls-tab">Calls</div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <div style={{ height: 400, width: '100%',}}>
                                            <DataGrid
                                                rows={Contactrows}
                                                columns={Contactcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="lead" role="tabpanel" aria-labelledby="lead-tab">
                                        <div style={{ height: 400, width: '100%',}}>
                                            <DataGrid
                                                rows={leadrows}
                                                columns={leadcolumns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                sx={{
                                                    fontSize:14,
                                                    fontWeight:500,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="duplicate" role="tabpanel" aria-labelledby="duplicate-tab">
                                        Duplicate
                                    </div>
                                    <div className="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
                                        Tasks
                                    </div>
                                    <div className="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
                                        Timeline
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="card bg_primary">
                <div className="card-body d-flex justify-content-between">
                    <div>
                        <h5 className='text-white'>Contact Details</h5>
                    </div>
                    <a href="/#" className="mx-3">
                        <i className="fas fa-window-minimize text-white"></i>
                    </a>
                    <a href="/#" className="mx-3">
                        <i className="fas fa-times text-white"></i>
                    </a>
                </div>
            </div> */}
        </div>
    )

}
export {TaskDetails}