/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {  toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  // MixedWidget2,
  // MixedWidget10,
  MixedWidget11,
  ChartsWidget1,
  // ListsWidget2,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget5,
  // ListsWidget6,
  ListsWidget1,
  // TablesWidget5,
  // TablesWidget10,
  // MixedWidget8,
} from '../../../_metronic/partials/widgets'

// import { PieChart } from 'react-minimal-pie-chart'
import { DashboardBarChart } from './barChart'
import { DashboardPieChart } from './pieChart'
import { Speedometer } from './speedometer'

import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'


// import { Card5 } from '../../../_metronic/partials/content/cards/Card5'
// import { Card3 } from '../../../_metronic/partials/content/cards/Card3'




const DashboardPage: FC = () => (
  <div className="dashboard_wrapper">
    {/* Stats card */}
      <div className="row">
        <div className="card-group d-flex flex-wrap justify-content-lg-between justify-content-center counter_card_group">
          <div className="col-lg-2 col-md-3 col-5 mb-4 me-2 me-xxl-3">
              <div className="card h-100 mb-4 counter_card br_10 bs_1">
                <div className="card-body d-flex align-items-center justify-content-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                      <img src={toAbsoluteUrl('/media/custom/menu-icons/contact.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Contact</h3>
                        <p className="mb-0">Total <span>#122</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-lg-2 col-md-3 col-5 mb-4 me-2 me-xxl-3">
              <div className="card h-100 mb-4 counter_card br_10 bs_1">
                <div className="card-body d-flex align-items-center justify-content-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                      <img src={toAbsoluteUrl('/media/custom/menu-icons/project.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Property</h3>
                        <p className="mb-0">Total <span>#122</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-lg-2 col-md-3 col-5 mb-4 me-2 me-xxl-3">
              <div className="card h-100 mb-4 counter_card active br_10 bs_1">
                <div className="card-body d-flex align-items-center justify-content-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                      <img src={toAbsoluteUrl('/media/custom/menu-icons/lead.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Lead</h3>
                        <p className="mb-0">Total <span>#122</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-lg-2 col-md-3 col-5 mb-4 me-2 me-xxl-3">
              <div className="card h-100 mb-4 counter_card br_10 bs_1">
                <div className="card-body d-flex align-items-center justify-content-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                      <img src={toAbsoluteUrl('/media/custom/menu-icons/task.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Task</h3>
                        <p className="mb-0">Total <span>#122</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-lg-2 col-md-3 col-5 mb-4 me-2 me-xxl-3">
              <div className="card h-100 mb-4 counter_card br_10 bs_1">
                <div className="card-body d-flex align-items-center justify-content-center py-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 bg-dark rounded_circle me-2 me-xxl-4">
                      <img src={toAbsoluteUrl('/media/custom/menu-icons/transaction.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1 ms-xl-1 ms-xxl-3">
                        <h3 className="mb-0">Transaction</h3>
                        <p className="mb-0">Total <span>#122</span></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    {/* Stats card */}

    {/* lead */}
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
          {/* <div className="card-group"> */}
              <div className="card-group">
                {/* Bar Chart starts*/}
                  <div className="col-md-6 col-12 mb-4">
                    <div className="card bs_1 h-100 me-3 br_15 bar_chart">
                      <div className="card-heade border-0 d-flex align-items-center justify-content-between px-5 pt-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Leads</span>
                          </h3>
                          <select className="form-select dash_btn">
                            <option selected>2022</option>
                            <option value="1">2021</option>
                            <option value="2">2020</option>
                            <option value="3">2019</option>
                          </select>
                      </div>
                      <div className="card-body pt-0 px-1">
                          <DashboardBarChart/>
                      </div>
                    </div>
                  </div>
                  {/* Bar Chart ends*/}

                  {/* Speedometer starts */}
                  <div className="col-md-6 col-12 mb-4">
                    <div className="card card-xl-stretch h-100 mb-xl-8 mb-3 bs_1 br_15">
                      <div className="card-heade border-0 d-md-flex align-items-center justify-content-between pt-5 px-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Goals</span>
                          </h3>
                          <div className="d-flex justify-content-end align-items-center">
                              <select className="form-select dash_btn me-2 mb-1">
                                <option selected>Call</option>
                                <option value="1">Ongoing</option>
                              </select>
                              <select className="form-select dash_btn me-2 mb-1">
                                  <option selected>Today</option>
                                  <option value="1">Yesterday</option>
                                  <option value="2">Last Week</option>
                                  <option value="2">Quaterly</option>
                                  <option value="2">Monthly</option>
                                  <option value="3">Yearly</option>
                              </select>
                              <select className="form-select dash_btn me-2 mb-1">
                                <option selected>Arjun</option>
                                <option value="1">Raj</option>
                                <option value="2">David</option>
                              </select>
                          </div>
                      </div>
                      <div className="card-body pt-0 px-2 d-flex justify-content-center align-items-center">
                          <Speedometer/>
                      </div>
                    </div>
                  </div>
                  {/* Speedometer ends */}
              </div>
              <div className="card-group">
                {/* Piechart starts */}
                  <div className="col-md-6 col-12 mb-4 pie_chart">
                    <div className="card card-xl-stretch h-100 me-3 bs_1 br_15">
                        <div className="card-heade border-0 d-flex align-items-center justify-content-between pt-5 px-5">
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Source</span>
                          </h3>
                          <select className="form-select dash_btn">
                            <option selected>2022</option>
                            <option value="1">2021</option>
                            <option value="2">2020</option>
                            <option value="3">2019</option>
                          </select>
                        </div>
                      <div className="card-body pt-0">
                        <DashboardPieChart/>
                      </div>
                    </div>
                  </div>
                {/* Piechart ends */}
                
                {/* tasks Widget */}
                  <div className="col-md-6 col-12 mb-4">
                      <ListsWidget1 className={'bs_1 br_15'}/>
                  </div>
                {/* tasks Widget */}
              </div> 
          {/* </div> */}
        </div>
      </div>

      {/* Funnel */}
      <div className="col-xxl-4 col-xl-3 col-12">
        <div className="card card-xl-stretch bs_1 br_15 h-100 mb-4">
            <div className="card-header border-0 d-md-flex align-items-center justify-content-between">
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Lead Funnel</span>
                </h3>
                <div className="d-flex justify-content-end align-items-center">
                    <select className="form-select dash_btn me-2 mb-1">
                      <option selected>Today</option>
                      <option value="1">Yesterday</option>
                      <option value="2">Weekly</option>
                      <option value="3">Yearly</option>
                    </select>
                    <select className="form-select dash_btn me-2 mb-1">
                      <option selected>Arjun</option>
                      <option value="1">Raj</option>
                      <option value="2">David</option>
                    </select>
                </div>
            </div>
          <div className="card-body pt-0 px-5">
            <FunnelChart 
                data={[
                  { name: 'New Lead', value: 252 },
                  { name: 'Option Sent', value: 105 },
                  { name: 'Inspected', value: 84 },
                  { name: 'Shortlisted', value: 72 },
                  { name: 'On Hold', value: 19 },
                  { name: 'Lost', value: 10 },
                  { name: 'won', value: 10 }
                ]}
                pallette= {
                  ['#00508f', '#1a6eb2', '#008dff', '#239bfd', '#64b5f6', '#64b5f6', '#b4d3ed']
                }
              />
          </div>
        </div>
      </div>
      {/* Funnel ends*/}

    </div>

  </div>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
