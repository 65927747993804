import React,{FC} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const TaskForm:  FC = () => {
    return(
        <div className='card shadow-none rounded-0 w-100'>
            <div className='card-header w-100' id='kt_contact_header'>
                <h3 className='card-title fw-bolder text-dark'>Add Task</h3>

                <div className='card-toolbar'>
                    <button
                    type='button'
                    className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                    id='kt_contact_close'
                    >
                        <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
                    {/* <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /> */}
                    </button>
                </div>
            </div>
            <div className='card-body position-relative' id='kt_contact_body'>
                <div className="accordion" id="accordionExample"> 
                    <form action="">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Task Details
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Task Type*</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option>General Task</option>
                                                    <option>Followup Call</option>
                                                    <option>Meeting</option>
                                                    <option>Site Visit</option>
                                                </select>      
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Priority</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option>Low</option>
                                                    <option>High</option>
                                                </select>      
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Task Time*</label>
                                            <div className="input-group mb-3 d-flex justify-content-between">
                                                <input type="date" className='date_field'/>
                                                <span className="my-auto">|</span>
                                                <input type="time" className='time_field'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Finish Time*</label>
                                                <div className="input-group mb-3 d-flex justify-content-between">
                                                    <input type="date" className='date_field'/>
                                                    <span className="my-auto">|</span>
                                                    <input type="time" className='time_field'/>
                                                </div>   
                                        </div>
                                        <div className="col-md-12 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Project</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option>Brigade</option>
                                                    <option>Brigade</option>
                                                </select>      
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Select Contact</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option>David</option>
                                                    <option>Nirmal</option>
                                                </select>      
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Agenda</label>
                                            <div className="input-group mb-3 input_prepend">
                                                <input type="number" className="form-control" placeholder="Enter Agenda"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Assign To</label>
                                            <div className="input-group mb-3">
                                                <select className="btn_secondary btn btn-sm w-100">
                                                    <option>David</option>
                                                    <option>Murugan</option>
                                                </select>      
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12 mb-3">
                                            <label htmlFor="basic-url" className="form-label">Task Note*</label>
                                            <textarea
                                            className='form-control border-0 p-2 resize-none min-h-25px br_10'
                                            data-kt-autosize='true'
                                            rows={5}
                                            placeholder='Enter your Notes'
                                            ></textarea>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card-footer py-5 text-center' id='kt_contact_footer'>
                <Link to='/dashboard' className='btn btn_primary text-primary'>
                Save
                <KTSVG
                    path='/media/custom/save_white.svg'
                    className='svg-icon-3 svg-icon-primary ms-2'
                />
                    {/* <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 svg-icon-primary'
                    /> */}
                </Link>
            </div>
        </div>
    )
}


export {TaskForm}