import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const OrgSettings: FC = () => {
    return(
        <div className="org_settings bg_white p-3 h-100">
            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Company Details</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-personal-tab" data-bs-toggle="pill" data-bs-target="#pills-personal" type="button" role="tab" aria-controls="pills-personal" aria-selected="false">Technical Details</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                    <form action="">
                        <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                                <div className="card bs_1">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3 col-xxl-2">
                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} className="img-thumbnail" alt="..."/>
                                                    <span className="btn btn-file d-flex flex-md-row p-2 p-md-4 w-75 my-3">
                                                        <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload Logo
                                                        <input type="file"/>
                                                    </span>
                                            </div>
                                            <div className="col-12 col-md-9 col-xl-8 col-xxl-7">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Name of Company</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Enter Company name"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Registered Address</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Address"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Locality</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Devanhalli</option>
                                                                <option>HSR Layout</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">City</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Bangalore</option>
                                                                <option>Chennai</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">State</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Karnataka</option>
                                                                <option>TamilNadu</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Phone Number</label>
                                                            <div className="input-group">
                                                                <input type="number" className="form-control" placeholder="Enter Phone Number"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-9 col-xl-11 col-12 mt-4">
                                                <div className="row">
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">GST Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="GST Number"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">CIN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="CIN Number"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">TAN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="TAN Number"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="PAN Number"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Upload Watermark Image</label>
                                                            <span className="btn btn-file d-flex flex-md-row p-2 p-md-4">
                                                                <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload
                                                                <input type="file"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Branch Details</label>
                                                            <div className="input-group mb-3">
                                                                <input type="text" className="form-control" placeholder="Branch Details"/>
                                                                <button className="btn btn-outline-secondary" type="button">
                                                                <i className="fa fa-plus-circle text_primary"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Address</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Address"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Locality</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Devanhalli</option>
                                                                <option>HSR Layout</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">City</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Bangalore</option>
                                                                <option>Chennai</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">State</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>Karnataka</option>
                                                                <option>TamilNadu</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Currency</label>
                                                            <select className="form-select btn-sm text-start">
                                                                <option value="default">Select</option>
                                                                <option>$</option>
                                                                <option>₹</option>
                                                            </select>  
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Website</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group my-4">
                                                            <h4>Social Media Links</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Facebook</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Linkedin</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Twitter</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Instagram</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-4">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Youtube</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="URL"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="tab-pane fade" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                <form action="">
                        <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                                <div className="card bs_1">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-9 col-xl-8 col-xxl-7">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">Email Server</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="Email Server"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group mb-4">
                                                            <label htmlFor="basic-url" className="form-label">DLT</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="DLT"/> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export {OrgSettings}