import React,{FC, useState} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

import DataTable from 'react-data-table-component'; 
import { TreeChart } from './treechart';

import { FullCalendarComponent } from './fullCalendar';
import { UserPerformance } from './performance';
import { TimeSheet } from './timesheet';
import { RoleProfile } from './roleProfile';


// Table data
const columns = [
    {
        name: 'ID',
        selector: (row: { employeeid: any; }) => row.employeeid,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row: { EmployeeName: any; }) => row.EmployeeName,
        sortable: true,
    },
    {
        name: 'Email',
        selector: (row: { Designation: any; }) => row.Designation,
        sortable: true,
    },
    {
        name: 'Phone',
        selector: (row: { Department: any; }) => row.Department,
        sortable: true,
    },
    {
        name: 'Contact Type',
        selector: (row: { Branch: any; }) => row.Branch,
        sortable: true,
    },
    {
        name: 'Company',
        selector: (row: { Photo: any; }) => row.Photo,
        sortable: true,
    },
    {
        name: 'Nationality',
        selector: (row: { PersonalPhone: any; }) => row.PersonalPhone,
        sortable: true,
    },
    {
        name: 'Country',
        selector: (row: { PersonalEmail: any; }) => row.PersonalEmail,
        sortable: true,
    },
    {
        name: 'Province',
        selector: (row: { OfficialPhone: any; }) => row.OfficialPhone,
        sortable: true,
    },
    {
        name: 'Town',
        selector: (row: { OfficialEmail: any; }) => row.OfficialEmail,
        sortable: true,
    },
    {
        name: 'Gender',
        selector: (row: { PermanentAddress: any; }) => row.PermanentAddress,
        sortable: true,
    },
    {
        name: 'Date of Birth',
        selector: (row: { CorrespondenceAddress: any; }) => row.CorrespondenceAddress,
        sortable: true,
    },
    {
        name: 'language',
        selector: (row: { EmergencyContact: any; }) => row.EmergencyContact,
        sortable: true,
    },
    {
        name: 'Source',
        selector: (row: { Aadhar: any; }) => row.Aadhar,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row: { Pan: any; }) => row.Pan,
        sortable: true,
    },
    {
        name: 'Last note',
        selector: (row: { Dob: any; }) => row.Dob,
        sortable: true,
    },
    {
        name: 'Assigned',
        selector: (row: { MaritalStatus: any; }) => row.MaritalStatus,
        sortable: true,
    },
    {
        name: 'created By',
        selector: (row: { AnniversaryDate: any; }) => row.AnniversaryDate,
        sortable: true,
    },
    {
        name: 'created By',
        selector: (row: { DateofJoin: any; }) => row.DateofJoin,
        sortable: true,
    },
    {
        name: 'created By',
        selector: (row: { PastEmployment: any; }) => row.PastEmployment,
        sortable: true,
    },
];
const data = [
    {
        employeeid: 9658,
        EmployeeName: 'Raj',
        Designation:'Seller',
        Department:'345678908768',
        Branch:'Buyer',
        Photo:'Brigade',
        PersonalPhone:'Indian',
        PersonalEmail:'India',
        OfficialPhone:'-',
        OfficialEmail:'chennai',
        PermanentAddress:'male',
        CorrespondenceAddress:'01/01/1996',
        EmergencyContact:'english',
        Aadhar:'google',
        Pan:'no status',
        Dob:'no note',
        MaritalStatus:'David',
        AnniversaryDate:'David',
        DateofJoin:'David',
        PastEmployment:'David',
        sortable: true, 
    },
]
// Table data


const UserManagement: FC = () => {
    
    const [toggle, setToggle] = useState(false);

    const handleHideData = () => {
        setToggle(!toggle);
    };

    return(
        <div className="user_manage_page bg_white h-100 p-4">
            <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Manage Users</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="user-charts-tab" data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">User Charts</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="manage-teams-tab" data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Manage Teams</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="attendance-tab" data-bs-toggle="pill" data-bs-target="#attendance" type="button" role="tab" aria-controls="attendance" aria-selected="false">Attendance</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="performance-tab" data-bs-toggle="pill" data-bs-target="#performance" type="button" role="tab" aria-controls="performance" aria-selected="false">Performance</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="time-sheets-tab" data-bs-toggle="pill" data-bs-target="#time-sheets" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Time Sheets</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="role-profile-tab" data-bs-toggle="pill" data-bs-target="#role-profile" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Role & Profile</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
                    <div className="d-flex justify-content-end">
                        <select className="me-4 mb-2 btn_secondary btn btn-sm" name="views" id="views" onChange={handleHideData}>
                            <option>Grid View</option>
                            <option>List View</option>
                        </select>
                    </div>
                        {!toggle && (
                            <div className="row">
                                <div className="card-group">
                                    <div className="col-xl-3 col-xxl-2 mb-4">
                                        <div className="card h-100 task_card user_card bs_1 me-3 me-xxl-5">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> 965896</p>
                                                </div>
                                                <div className="d-flex">
                                                    <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                    <div className="btn-group">
                                                        <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                            <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                            <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="d-flex flex-center">
                                                    <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} className="user_img" alt='' />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                        <p className="mb-0 contact_name">Ms.Riya</p>
                                                    </div>
                                                    <div>
                                                        <p>Assistant Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Sales</small> */}
                                                                    <p className="mb-0 fw-500">Sales</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Branch</small> */}
                                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Official Ph.no</small> */}
                                                                    <p className="mb-0 fw-500">9658569856</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Email</small> */}
                                                                    <p className="mb-0 fw-500">ram@gmail.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-xxl-2 mb-4">
                                        <div className="card h-100 task_card user_card bs_1 me-3 me-xxl-5">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> 965896</p>
                                                </div>
                                                <div className="d-flex">
                                                    <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                    <div className="btn-group">
                                                        <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                            <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                            <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="d-flex flex-center">
                                                    <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} className="user_img" alt='' />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                        <p className="mb-0 contact_name">Ms.Tara</p>
                                                    </div>
                                                    <div>
                                                        <p>Assistant Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Sales</small> */}
                                                                    <p className="mb-0 fw-500">Sales</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Branch</small> */}
                                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Official Ph.no</small> */}
                                                                    <p className="mb-0 fw-500">9658569856</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Email</small> */}
                                                                    <p className="mb-0 fw-500">ram@gmail.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-xxl-2 mb-4">
                                        <div className="card h-100 task_card user_card bs_1 me-3 me-xxl-5">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> 965896</p>
                                                </div>
                                                <div className="d-flex">
                                                    <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                    <div className="btn-group">
                                                        <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                            <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                            <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="d-flex flex-center">
                                                    <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} className="user_img" alt='' />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                        <p className="mb-0 contact_name">Ms.Samantha</p>
                                                    </div>
                                                    <div>
                                                        <p>Assistant Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Sales</small> */}
                                                                    <p className="mb-0 fw-500">Sales</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Branch</small> */}
                                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Official Ph.no</small> */}
                                                                    <p className="mb-0 fw-500">9658569856</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Email</small> */}
                                                                    <p className="mb-0 fw-500">ram@gmail.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-xxl-2 mb-4">
                                        <div className="card h-100 task_card user_card bs_1 me-3 me-xxl-5">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> 965896</p>
                                                </div>
                                                <div className="d-flex">
                                                    <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                    <div className="btn-group">
                                                        <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                            <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                            <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="d-flex flex-center">
                                                    <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} className="user_img" alt='' />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                        <p className="mb-0 contact_name">Ms.Amy</p>
                                                    </div>
                                                    <div>
                                                        <p>Assistant Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Sales</small> */}
                                                                    <p className="mb-0 fw-500">Sales</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Branch</small> */}
                                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Official Ph.no</small> */}
                                                                    <p className="mb-0 fw-500">9658569856</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Email</small> */}
                                                                    <p className="mb-0 fw-500">ram@gmail.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-xxl-2 mb-4">
                                        <div className="card h-100 task_card user_card bs_1 me-3 me-xxl-5">
                                            <div className="card-header px-0 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <p className="mb-0"><span>ID:</span> 965896</p>
                                                </div>
                                                <div className="d-flex">
                                                    <a href="/#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                    <div className="btn-group">
                                                        <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                            <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                            <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                            <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="d-flex flex-center">
                                                    <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} className="user_img" alt='' />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <div className="d-flex justify-content-center mb-2 text-center">
                                                        <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                        <p className="mb-0 contact_name">Ms.Jessica</p>
                                                    </div>
                                                    <div>
                                                        <p>Assistant Manager</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Sales</small> */}
                                                                    <p className="mb-0 fw-500">Sales</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Branch</small> */}
                                                                    <p className="mb-0 fw-500">Bangalore</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Official Ph.no</small> */}
                                                                    <p className="mb-0 fw-500">9658569856</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-start single_item mb-3">
                                                                <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="svg_icon me-2"/>
                                                                <div className="d-flex flex-column">
                                                                    {/* <small className="text_light">Email</small> */}
                                                                    <p className="mb-0 fw-500">ram@gmail.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}

                            {toggle && (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                    />
                                </>
                            )}
                </div>
                <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
                    <TreeChart/>
                </div>
                <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
                    <div>
                        <img src={toAbsoluteUrl('/media/custom/manage_teams.png')} className="manage_teams_img img-fluid" alt="" />
                    </div>
                </div>
                <div className="tab-pane fade" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
                   <FullCalendarComponent/>
                </div>
                <div className="tab-pane fade" id="performance" role="tabpanel" aria-labelledby="performance-tab">
                    <UserPerformance/>   
                </div>
                <div className="tab-pane fade" id="time-sheets" role="tabpanel" aria-labelledby="time-sheets-tab">
                    <TimeSheet/>
                </div>
                <div className="tab-pane fade" id="role-profile" role="tabpanel" aria-labelledby="role-profile-tab">
                    <RoleProfile/>
                </div>
            </div>
        </div>
    )
}
export {UserManagement}