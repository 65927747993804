import React,{FC, useState} from 'react'

// import { Dropdown1 } from '../../../../_metronic/partials'


import DataTable from 'react-data-table-component'; 
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';

const columns = [
    {
        name: 'ID',
        selector: (row: { id: any; }) => row.id,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row: { name: any; }) => row.name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: (row: { email: any; }) => row.email,
        sortable: true,
    },
    {
        name: 'Phone',
        selector: (row: { phone: any; }) => row.phone,
        sortable: true,
    },
    {
        name: 'Contact Type',
        selector: (row: { contactType: any; }) => row.contactType,
        sortable: true,
    },
    {
        name: 'Company',
        selector: (row: { company: any; }) => row.company,
        sortable: true,
    },
    {
        name: 'Nationality',
        selector: (row: { nationality: any; }) => row.nationality,
        sortable: true,
    },
    {
        name: 'Country',
        selector: (row: { country: any; }) => row.country,
        sortable: true,
    },
    {
        name: 'Province',
        selector: (row: { province: any; }) => row.province,
        sortable: true,
    },
    {
        name: 'Town',
        selector: (row: { town: any; }) => row.town,
        sortable: true,
    },
    {
        name: 'Gender',
        selector: (row: { gender: any; }) => row.gender,
        sortable: true,
    },
    {
        name: 'Date of Birth',
        selector: (row: { dob: any; }) => row.dob,
        sortable: true,
    },
    {
        name: 'language',
        selector: (row: { language: any; }) => row.language,
        sortable: true,
    },
    {
        name: 'Source',
        selector: (row: { source: any; }) => row.source,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row: { status: any; }) => row.status,
        sortable: true,
    },
    {
        name: 'Last note',
        selector: (row: { lastNote: any; }) => row.lastNote,
        sortable: true,
    },
    {
        name: 'Assigned',
        selector: (row: { assignedTo: any; }) => row.assignedTo,
        sortable: true,
    },
    {
        name: 'created By',
        selector: (row: { createdBy: any; }) => row.createdBy,
        sortable: true,
    },
];

const data = [
    {
        id: 9658,
        name: 'Raj',
        email:'Raj@gmail.com',
        phone:'345678908768',
        contactType:'Buyer',
        company:'Brigade',
        nationality:'Indian',
        country:'India',
        province:'-',
        town:'chennai',
        gender:'male',
        dob:'01/01/1996',
        language:'english',
        source:'google',
        status:'no status',
        lastNote:'no note',
        assignedTo:'David',
        createdBy:'David',
        sortable: true, 
    },
    {
        id: 2568,
        name: 'Singh',
        email:'singh@gmail.com',
        phone:'345678908768',
        contactType:'Buyer',
        company:'Brigade',
        nationality:'Indian',
        country:'India',
        province:'-',
        town:'chennai',
        gender:'male',
        dob:'01/01/1996',
        language:'english',
        source:'google',
        status:'no status',
        lastNote:'no note',
        assignedTo:'David',
        createdBy:'David',
        sortable: true, 
    },
    {
        id: 3568,
        name: 'Ram',
        email:'Ram@gmail.com',
        phone:'345678908768',
        contactType:'Buyer',
        company:'Brigade',
        nationality:'Indian',
        country:'India',
        province:'-',
        town:'chennai',
        gender:'male',
        dob:'01/01/1996',
        language:'english',
        source:'google',
        status:'no status',
        lastNote:'no note',
        assignedTo:'David',
        createdBy:'David',
        sortable: true, 
    },
]

type Props = {
    className: string
  }

const ContactPage: FC = () => {

    const [toggle, setToggle] = useState(false);

    const handleHideData = () => {
        setToggle(!toggle);
    };


    return (
        <div>
           <div className="d-flex justify-content-end">
                <select className="me-4 mb-2 btn_secondary btn btn-sm" name="views" id="views" onChange={handleHideData}>
                    <option>Grid View</option>
                    <option>List View</option>
                </select>
           </div>

            {/* listview */}
            {!toggle && (
                <div className="contact_page">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            235648
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name">Mr.Raj</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                        <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                        <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                                </div>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Buyer</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Google Ads</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">Ram Kumar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">30 Dec 2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            13
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            16
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            1
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            88
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                        <option selected>Status</option>
                                                        <option>New Contact</option> 
                                                        <option>Verified</option>
                                                        <option>Invalid</option>
                                                        <option>UnAnswered</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            235648
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name">Mr.Raj</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                        <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                        <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-4.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                                </div>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Buyer</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Google Ads</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">Ram Kumar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">30 Dec 2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            13
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            16
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            1
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            88
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                        <option selected>Status</option>
                                                        <option>New Contact</option> 
                                                        <option>Verified</option>
                                                        <option>Invalid</option>
                                                        <option>UnAnswered</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            235648
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name">Mr.Raj</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                        <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                        <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-17.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                                </div>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Buyer</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Google Ads</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">Ram Kumar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">30 Dec 2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            13
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            16
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            1
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            88
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                        <option selected>Status</option>
                                                        <option>New Contact</option> 
                                                        <option>Verified</option>
                                                        <option>Invalid</option>
                                                        <option>UnAnswered</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            235648
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name">Mr.Raj</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                        <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                        <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                                </div>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Buyer</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Google Ads</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">Ram Kumar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">30 Dec 2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            13
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            16
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            1
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            88
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                        <option selected>Status</option>
                                                        <option>New Contact</option> 
                                                        <option>Verified</option>
                                                        <option>Invalid</option>
                                                        <option>UnAnswered</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xxl-3 col-xl-4 mb-3">
                                <div className="card h-100 mb-5 mb-xl-8 mx-2 bs_1">
                                    <div className='card-body px-3 pt-3 pb-0'>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="d-flex align-items-center">
                                                <form action="">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                        <label className="form-check-label id_label" htmlFor="exampleCheck1">
                                                            235648
                                                        </label>
                                                    </div>
                                                </form>
                                                <div className="ms-3 ml-2 d-flex align-items-center">
                                                    <img src={toAbsoluteUrl('/media/custom/user.svg')} alt="" className="icon me-2" />
                                                    <p className="mb-0 contact_name">Mr.Raj</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a href="#" id='kt_expand_toggle'><img src={toAbsoluteUrl('/media/custom/expand.svg')} alt="" className="icon me-2"/></a>
                                                {/* <a href="#"><img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/></a> */}
                                                <div className="btn-group">
                                                    <a className="" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                        <img src={toAbsoluteUrl('/media/custom/more.svg')} alt="" className="icon me-2"/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                                                        <li><a className="dropdown-item" href="#">Edit</a></li>
                                                        <li><a className="dropdown-item" href="#">Create Proposal</a></li>
                                                        <li><a className="dropdown-item" href="#">Requirment Form</a></li>
                                                        <li><a className="dropdown-item" href="#">Register Contact</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img src={toAbsoluteUrl('/media/avatars/300-25.jpg')} className="user_img" alt='' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={toAbsoluteUrl('/media/custom/envelope.svg')} alt="" className="icon me-1"/>
                                                    <p className="mb-0 fixed_text">raj2001@gmail.com</p>
                                                </div>
                                                <div className="d-xxl-flex justify-content-between">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/phone.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            <span><img src={toAbsoluteUrl('/media/custom/whatsapp.svg')} alt="" className="icon me-1" /></span>
                                                             8965896547
                                                        </p>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/buyer.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Contact Type</small>
                                                                        <p className="mb-0 fw-500">Buyer</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                <img src={toAbsoluteUrl('/media/custom/google_ads.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Source</small>
                                                                        <p className="mb-0 fw-500">Google Ads</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/assign.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Assigned To</small>
                                                                        <p className="mb-0 fw-500">Ram Kumar</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-6 mb-3">
                                                            <div className="task_content_single">
                                                                <div className="d-flex align-items-start single_item">
                                                                    <img src={toAbsoluteUrl('/media/custom/calendar.svg')} alt="" className="me-2"/>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text_light">Created Date</small>
                                                                        <p className="mb-0 fw-500">30 Dec 2022</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 p-1">
                                            <div className="row">
                                                <div className="col-7 col-xl-8 icons_bar d-flex flex-wrap">
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Notes">
                                                        <img src={toAbsoluteUrl('/media/custom/notes.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            13
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="File">
                                                        <img src={toAbsoluteUrl('/media/custom/file.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            16
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Message">
                                                        <img src={toAbsoluteUrl('/media/custom/message.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="sec Contact">
                                                        <img src={toAbsoluteUrl('/media/custom/sec_contact.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            1
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Lead">
                                                        <img src={toAbsoluteUrl('/media/custom/lead.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            5
                                                        </span>
                                                    </a>
                                                    <a href="#" id='kt_expand_toggle' className="btn btn-sm icon_primary rounded-circle position-relative"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Task">
                                                        <img src={toAbsoluteUrl('/media/custom/task.svg')} className="svg_icon" alt='' />
                                                        <span className="position-absolute top-0 start-100 badge rounded-pill bg-danger">
                                                            88
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="col-5 col-xl-4 d-flex align-items-center justify-content-end">
                                                    <select className="form-select toggle_white toggle_white btn_primary rounded-pill btn-sm cursor-pointer status_btn" aria-label="Default select example">
                                                        <option selected>Status</option>
                                                        <option>New Contact</option> 
                                                        <option>Verified</option>
                                                        <option>Invalid</option>
                                                        <option>UnAnswered</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        
            
            {/* Grid view */}
            {toggle && (
                <>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </>
            )}
        </div>
    )
}

  export { ContactPage }

  