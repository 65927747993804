import React,{FC} from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardBarChart } from '../dashboard/barChart';
import { DashboardPieChart } from '../dashboard/pieChart';

const FavoriteLead: FC = () => {
    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-lg-3 col-xl-3 col-xxl-2">
                    <div className="card h-100 bg-light type_card">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <ul className="px-3">
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Lead Report 
                                </li>
                                <li className="text-primary">
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Lead Source 
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Transaction Report 
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Site Visit
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Builder Wise Revenue Report
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Project Wise Lead Report
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    City Wise Lead Report
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-xl-9 col-xxl-10">
                    <div className="row">
                        <div className="card-group">
                            <div className="col-md-6 col-xl-6 col-xxl-4 mb-4">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-header">
                                        <h4>Lead Source</h4>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <div className="d-flex me-3">
                                                    <i className="fa fa-star me-2 text-warning" aria-hidden="true"></i>
                                                    <i className="fa fa-download me-2" aria-hidden="true"></i>
                                                </div>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Teams</option>
                                                    <option value="1">Brigade Sales Team</option>
                                                    <option value="1">Godrej Sales Team</option>
                                                    <option value="1">Shriram Sales Team</option>
                                                </select>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Monthly</option>
                                                    <option value="1">Yesterday</option>
                                                    <option value="2">Last Week</option>
                                                    <option value="2">Quaterly</option>
                                                    <option value="2">Monthly</option>
                                                    <option value="3">Yearly</option>
                                                </select>
                                                <select className="form-select dash_btn me-2 mb-1">
                                                    <option selected>Arjun</option>
                                                    <option value="1">Raj</option>
                                                    <option value="2">David</option>
                                                </select>
                                            </div>
                                    </div>
                                    <div className="card-body p-3">
                                        <DashboardPieChart/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-xl-6 col-xxl-4 mb-4">
                                <div className="card h-100 bs_1 mx-3">
                                    <div className="card-header">
                                        <h4>Source</h4>
                                    </div>
                                    <div className="card-body p-3">
                                        <DashboardPieChart/>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export{FavoriteLead}