import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


const Finance: FC = () => {
    return(
        <div className="user_manage_page bg_white h-100 p-4">
        <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-org-tab" data-bs-toggle="pill" data-bs-target="#pills-org" type="button" role="tab" aria-controls="pills-org" aria-selected="true">Fee Confirmation</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="user-charts-tab" data-bs-toggle="pill" data-bs-target="#user-charts" type="button" role="tab" aria-controls="user-charts" aria-selected="false">Proforma invoice</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="manage-teams-tab" data-bs-toggle="pill" data-bs-target="#manage-teams" type="button" role="tab" aria-controls="manage-teams" aria-selected="false">Invoice</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="attendance-tab" data-bs-toggle="pill" data-bs-target="#attendance" type="button" role="tab" aria-controls="attendance" aria-selected="false">Collection</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="performance-tab" data-bs-toggle="pill" data-bs-target="#performance" type="button" role="tab" aria-controls="performance" aria-selected="false">Expenses</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="time-sheets-tab" data-bs-toggle="pill" data-bs-target="#time-sheets" type="button" role="tab" aria-controls="time-sheets" aria-selected="false">Incentive</button>
            </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-org" role="tabpanel" aria-labelledby="pills-org-tab">
               Under Construction
            </div>
            <div className="tab-pane fade" id="user-charts" role="tabpanel" aria-labelledby="user-charts-tab">
            Under Construction
            </div>
            <div className="tab-pane fade" id="manage-teams" role="tabpanel" aria-labelledby="manage-teams-tab">
            Under Construction
            </div>
            <div className="tab-pane fade" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
            Under Construction
            </div>
            <div className="tab-pane fade" id="performance" role="tabpanel" aria-labelledby="performance-tab">
            Under Construction  
            </div>
            <div className="tab-pane fade" id="time-sheets" role="tabpanel" aria-labelledby="time-sheets-tab">
            Under Construction
            </div>
        </div>
    </div>
    )
}

export {Finance}