/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import { useLocation } from 'react-router-dom'



const Toolbar1: FC = (props) => {

  const {classes} = useLayout()
  let location = useLocation();
  console.log("location",location)

  const isMenu = location.pathname.includes('menu');
  const isUserSettings = location.pathname.includes('user-settings');
  const isReports = location.pathname.includes('reports');

  // const [selects,setSelects]=useState();



  return (
    <div className='toolbar d-flex align-items-end' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        {/* <DefaultTitle /> */}

        <div  className="menu_bar d-flex align-items-center justify-content-end w-100">
          {!isMenu &&
              <>
              {/* dashboard Menu */}
              {/* <div className="d-flex">
                <MenuItem title='Crm' to='/' />
                <MenuItem title='Intercom' to='/' />
                <MenuItem title='Support' to='/' />
                <MenuItem title='Activity Monitor' to='/' />
              </div> */}

                  {/* <div className='d-flex align-items-center py-1'>
                    <div className='me-4'>
                      <a
                        href='#'
                        className='btn btn-sm btn-flex btn_secondary btn-active-primary fw-bolder'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen031.svg'
                          className='svg-icon-5 svg-icon-gray-500 me-1'
                        />
                        Filter
                      </a>
                    </div>
                      <a
                        href='#'
                        className='btn btn-sm btn_primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        id='kt_toolbar_primary_button'
                      >
                        Create
                      </a>
                    </div> */}
              </>
          }
          {isMenu &&
              <>
              <div className='d-flex button_bar'>
                  <a className="me-4 btn btn-sm me-4" id='kt_contact_toggle'>Add+</a>
                  <select className="form-select me-4 btn btn-sm" name="type" id="type">
                      <option value="default">Type</option>
                      <option value="date">Residential</option>
                      <option value="dob">Commercial</option>
                      <option value="Plot">Plot</option>
                      <option value="developer">Developer</option>
                  </select>
                  <select className="form-select me-4 btn btn-sm" name="action" id="action">
                      <option value="default">Action</option>
                      <option value="add">Add</option>
                      <option value="edit">Edit</option>
                      <option value="bulk">Bulk Edit</option>
                      <option value="delete">Delete</option>
                      <option value="archive">Archive</option>
                      <option value="import">Import</option>
                      <option value="export">Export</option>
                      <option value="selectall">Select All</option>
                  </select>
                  <select className="form-select me-4 btn btn-sm" name="sort" id="sort">
                      <option value="default">Sort By</option>
                      <option value="date">Created Date</option>
                      <option value="date">Updated Date</option>
                      <option value="date">Alphabetical</option>
                  </select>

              </div>
              <div className="d-flex">
                  <a className="me-4 btn btn-sm me-4" id='kt_filter_toggle'>
                    <KTSVG path='/media/custom/header-icons/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
                  <a className="me-4 btn btn-sm me-4">
                    <KTSVG path='/media/custom/header-icons/search.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
              </div>
              </>
          }
          {isUserSettings &&
              <>
              <div className='d-flex button_bar'>
                  <a className="me-4 btn btn-sm me-4" id='kt_usersettings_toggle'>Add+</a>
              </div>
              </>
          }
          { isReports &&
              <>
              <div className='d-flex button_bar'>
                  <a className="me-4 btn btn-sm me-4" id='kt_usersettings_toggle'>Create Chart+</a>
              </div>
              <div className="d-flex">
                  <a className="me-4 btn btn-sm me-4" id='kt_filter_toggle'>
                    <KTSVG path='/media/custom/header-icons/filter.svg' className='svg-icon-5 svg-icon-gray-500 me-1'/>
                  </a>
              </div>
              </>
          }
        </div>

        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}



export {Toolbar1}