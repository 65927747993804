import React,{FC, useState} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';


const AddUserSettings:  FC = () => {
    const Input = () => {
        return <input className="form-control" placeholder="Your input here"/>;
      };
    
      const [inputList, setInputList] = useState<JSX.Element>();
    //   const getInput = <Input/>
      const onAddBtnClick = () => {
        setInputList(<Input/>);
      };

      
    return(
        
        <div className='card shadow-none rounded-0 w-100'>
          <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_usersettings_header'>
            <h3 className='card-title fw-bolder text-dark'>Add User</h3>
            
            <div className='card-toolbar'>
              <div>
                <div className="input-group form_search me-3">
                  <input type="text" className="form-control" placeholder="Search"/>
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                    </button>
                  </div>
                </div>
              </div>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_usersettings_close'
              >
                  <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              </button>
            </div>
          </div>
          <div className='card-body position-relative' id='kt_usersettings_body'>
            <div className="accordion" id="accordionExample"> 
                <form action="">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Basic Details
                        </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Employee Name</label>
                                        <div className="input-group first mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="Employee Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Employee ID*</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Id"/>
                                        </div> 
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Designation</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Designation"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Department</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Department"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Branch</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Department"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Photo</label>
                                        <span className="btn btn-file">
                                            <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload <input type="file"/>
                                        </span>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Personal Phone Number*</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option value="date">+91</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="Enter your Phone Number"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Personal Email ID</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Enter your email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Official Phone Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option value="date">+91</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="Enter Phone Number"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Official Email ID</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Enter your email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Emergency Contact Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                                <option value="date">+91</option>
                                            </select>
                                            <input type="text" className="form-control" placeholder="Enter Phone Number"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Aadhar Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" placeholder="Enter Aadhar"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">PAN Number</label>
                                        <div className="input-group mb-3 input_prepend">
                                            <input type="text" className="form-control" placeholder="Enter PAN"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Date of Birth</label>
                                        <div className="input-group mb-3">
                                            <input type="date" className="form-control" placeholder="date"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select btn btn-sm w-100">
                                                <option value="buyer">Single</option>
                                                <option value="seller">Married</option>
                                            </select>      
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Anniversary Date</label>
                                        <div className="input-group mb-3">
                                            <input type="date" className="form-control" placeholder="date"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Date of Joining</label>
                                        <div className="input-group mb-3">
                                            <input type="date" className="form-control" placeholder="date"/> 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Past Employment History</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Employment history"/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Address Details
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Permanent Address</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Permanent Address"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Correspondence Address</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Correspondence Address"/>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Locality*</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100">
                                                <option value="default" disabled>Select Locality</option>
                                                <option>HSR Layout</option>
                                                <option>Koramangala</option>
                                            </select>      
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">City*</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100">
                                                <option value="default" disabled>Select City</option>
                                                <option>Bangalore</option>
                                                <option>Chennai</option>
                                            </select>      
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">State*</label>
                                        <div className="input-group mb-3">
                                            <select className="btn_secondary btn btn-sm w-100">
                                                <option value="default" disabled>Select State</option>
                                                <option>Chennai</option>
                                                <option>Coimbatore</option>
                                                <option>Vellore</option>
                                            </select>      
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label htmlFor="basic-url" className="form-label">Zip code</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder=""/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
          <div className='card-footer py-5 text-center' id='kt_usersettings_footer'>
            <Link to='/dashboard' className='btn btn_primary text-primary'>
            Save
              <KTSVG
                path='/media/custom/save_white.svg'
                className='svg-icon-3 svg-icon-primary ms-2'
              />
            </Link>
          </div>
        </div>
    )
}


export {AddUserSettings}