import React,{FC} from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { DashboardPieChart } from '../dashboard/pieChart';

const TaskLead: FC = () => {
    return(
        <section className='report_lead'>
            <div className="row">
                <div className="col-lg-3 col-xl-3 col-xxl-2">
                    <div className="card h-100 bg-light type_card">
                        <div className="card-header">
                            <div className="input-group form_search me-3">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button">
                                    <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <ul className="px-3">
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Task Status Report 
                                </li>
                                <li>
                                    <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr001.svg')} className="svg_icon me-2" alt='' />
                                    Task Type Report  
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-xl-9 col-xxl-10">
                   
                </div>
            </div>
        </section>
    )
}
export{TaskLead}