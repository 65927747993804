import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
// import { Accordion } from 'react-bootstrap'

import {ProjectForm} from '../../../../app/pages/property/propertyform'
import { TaskForm } from '../../../../app/pages/task/taskform';
import { ContactForm } from '../../../../app/pages/contact/contactform';
import { LeadForm } from '../../../../app/pages/lead/leadform';
import {AddUserSettings} from '../../../../app/pages/settings/userManagement/addUser'

// const AddContact: FC = () => (
function AddContact(){

  let location = useLocation();
  console.log("location",location)

  const isContact = location.pathname.includes('contact');
  const isTask = location.pathname.includes('task');
  const isProperty = location.pathname.includes('property');
  const isLead = location.pathname.includes('lead');
  const isUserSettings = location.pathname.includes('user-settings');

  return(
  <div>

    {/* Add Contact Drawer */}
    {isContact &&
    <div
        id='kt_contact'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='contact'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'100%', 'md': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_contact_toggle'
        data-kt-drawer-close='#kt_contact_close'
      >
        <ContactForm/>
    </div>
    }

    {/* Add Task Drawer */}
    {isTask &&
        <div
            id='kt_contact'
            className='bg-white'
            data-kt-drawer='true'
            data-kt-drawer-name='contact'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_contact_toggle'
            data-kt-drawer-close='#kt_contact_close'
          >
           <TaskForm/>
        </div>
    }

    {/* Add Property Drawer */}
    {isProperty &&
          <div
            id='kt_contact'
            className='bg-white'
            data-kt-drawer='true'
            data-kt-drawer-name='contact'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_contact_toggle'
            data-kt-drawer-close='#kt_contact_close'
          >
            <ProjectForm/>
          </div>
    }

    {/* Add Lead Drawer */}
    {isLead &&
          <div
            id='kt_contact'
            className='bg-white'
            data-kt-drawer='true'
            data-kt-drawer-name='contact'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_contact_toggle'
            data-kt-drawer-close='#kt_contact_close'
          >
            <LeadForm/>
          </div>
    }

    {/* User Settings Drawer */}
    {isUserSettings &&
          <div
            id='kt_usersettings'
            className='bg-white'
            data-kt-drawer='true'
            data-kt-drawer-name='contact'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'100%', 'md': '700px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_usersettings_toggle'
            data-kt-drawer-close='#kt_usersettings_close'
          >
            <AddUserSettings/>
          </div>
    }
  </div>
  )
}


export {AddContact}
// export default AddContact