import React,{FC, useState} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'


const ContactForm:  FC = () => {
    const Input = () => {
        return <input className="form-control" placeholder="Your input here"/>;
      };
    
      const [inputList, setInputList] = useState<JSX.Element>();
    //   const getInput = <Input/>
      const onAddBtnClick = () => {
        setInputList(<Input/>);
      };

      
    return(
        
        <div className='card shadow-none rounded-0 w-100'>
          <div className='card-header w-100 d-flex align-items-center justify-content-between' id='kt_contact_header'>
            <h3 className='card-title fw-bolder text-dark'>Add Contact</h3>
            
            <div className='card-toolbar'>
              <div>
                <div className="input-group form_search me-3">
                  <input type="text" className="form-control" placeholder="Search"/>
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <img src={toAbsoluteUrl('/media/custom/header-icons/search.svg')} className="svg_icon" alt='' />
                    </button>
                  </div>
                </div>
              </div>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_contact_close'
              >
                  <img src={toAbsoluteUrl('/media/custom/cancel_black.svg')} className="svg_icon" alt='' />
              </button>
            </div>
          </div>
          <div className='card-body position-relative' id='kt_contact_body'>
          
          <div className="accordion" id="accordionExample"> 
          <form action="">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Basic Details
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">FirstName*</label>
                            <div className="input-group first mb-3 input_prepend">
                              <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                  <option value="Mr">Mr</option>
                                  <option value="Mrs">Mrs</option>
                              </select>
                              <input type="text" className="form-control" placeholder="Firstname"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label">LastName*</label>
                          <div className="input-group">
                              <input type="text" className="form-control" placeholder="Lastname"/>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Phone Number*</label>
                            <div className="input-group mb-3 input_prepend">
                              <select className="px-2 py-2 btn_secondary btn btn-sm prepend">
                                  <option value="date">+91</option>
                              </select>
                              <input type="text" className="form-control" placeholder="Enter your Phone Number"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Email Address*</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" placeholder="Enter your email"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Contact Type</label>
                            <div className="input-group mb-3">
                              <select className="form-select btn btn-sm w-100">
                                  <option value="buyer">Buyer</option>
                                  <option value="seller">Seller</option>
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Contact Category</label>
                            <div className="input-group mb-3">
                              <select className="form-select btn btn-sm w-100">
                                  <option value="default" disabled>Select Any one</option>
                                  <option>Residential</option>
                                  <option>Plot</option>
                                  <option>Commercial</option>
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Status</label>
                            <div className="input-group mb-3">
                              <select className="form-select btn btn-sm w-100">
                                  <option>New Contact</option> 
                                  <option>Verified</option>
                                  <option>Invalid</option>
                                  <option>UnAnswered</option>
                              </select>      
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label htmlFor="basic-url" className="form-label">Assign to</label>
                            <div className="input-group mb-3">
                              <select className="form-select btn btn-sm w-100">
                                  <option>Dennis</option>
                                  <option>David</option>
                              </select>      
                            </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Address Details
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Address line 1</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder=""/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Address line 2</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder=""/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Locality*</label>
                            <div className="input-group mb-3">
                              <select className="btn_secondary btn btn-sm w-100">
                                  <option value="default" disabled>Select Locality</option>
                                  <option>HSR Layout</option>
                                  <option>Koramangala</option>
                              </select>      
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">City*</label>
                            <div className="input-group mb-3">
                              <select className="btn_secondary btn btn-sm w-100">
                                  <option value="default" disabled>Select City</option>
                                  <option>Bangalore</option>
                                  <option>Chennai</option>
                              </select>      
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">State*</label>
                            <div className="input-group mb-3">
                              <select className="btn_secondary btn btn-sm w-100">
                                  <option value="default" disabled>Select State</option>
                                  <option>Chennai</option>
                                  <option>Coimbatore</option>
                                  <option>Vellore</option>
                              </select>      
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Zip code</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder=""/>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  More Details
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      <div className="row">
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">National ID</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Enter National ID"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Group</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Select your group"/>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Source</label>
                            <div className="input-group mb-3">
                              <select className="btn_secondary btn btn-sm w-100">
                                  <option value="default" disabled>Select Your Source</option>
                                  <option>Chennai</option>
                                  <option>Coimbatore</option>
                                  <option>Vellore</option>
                              </select>      
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Do not Disturb</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option value="default" disabled>Do not SMS</option>
                                        <option>Do not call</option>
                                    </select>      
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Marital Status</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option>Single</option>
                                        <option>Married</option>
                                    </select>      
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Gender</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option value="default" disabled>Male</option>
                                        <option>Female</option>
                                        <option>Others</option>
                                    </select>      
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">No of Children</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>      
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <label htmlFor="basic-url" className="form-label">Wedding Anniversary</label>
                            <div className="input-group mb-3">
                                <input type="date" className="form-control" placeholder="date"/> 
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Nationality</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option>Indian</option>
                                    </select>      
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Language</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option>English</option>
                                        <option>Tamil</option>
                                    </select>      
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Pet owner</label>
                                  <div className="input-group mb-3">
                                    <select className="btn_secondary btn btn-sm w-100">
                                        <option>Dog</option>
                                        <option>Cat</option>
                                        <option>None</option>
                                    </select>      
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">D.O.B</label>
                                  <div className="input-group mb-3">
                                      <input type="date" className="form-control" placeholder="dob"/> 
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-12 mb-3">
                            <div className="bg_white br_15 p-4 upload_part position-relative">
                                <button className="btn btn-transparent upload_add" onClick={onAddBtnClick}>
                                <i className="fa fa-plus-circle text_primary" aria-hidden="true"></i>
                                </button>
                                <div className="row">
                                  <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">Person ID Documents</label>
                                      <div className="input-group mb-3">
                                          <select className="btn_secondary btn btn-sm w-100">
                                              <option value="default" disabled>Document Type</option>
                                              <option>PAN</option>
                                              <option>Aadhaar</option>
                                          </select>   
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="basic-url" className="form-label">ID number</label>
                                      <div className="input-group mb-3">
                                          <input type="text" className="form-control" placeholder="ID Number"/>   
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <div className="d-flex justify-content-center">
                                          <span className="btn btn-file">
                                          <i className="fa fa-upload my-2" aria-hidden="true"></i>Upload <input type="file"/>
                                          </span>
                                      </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                  {inputList}
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Social Links</label>
                                  <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder=""/>
                                      <button className="btn btn-outline-secondary" type="button" id="button-addon2">
                                      <i className="fa fa-plus-circle text_primary" aria-hidden="true"></i>
                                      </button>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <label htmlFor="basic-url" className="form-label">Remarks</label>
                                  <div className="input-group mb-3">
                                      <input type="text" className="form-control" placeholder=""/>
                                  </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              </form>
            </div>

          </div>
          <div className='card-footer py-5 text-center' id='kt_contact_footer'>
            <Link to='/dashboard' className='btn btn_primary text-primary'>
            Save
              <KTSVG
                path='/media/custom/save_white.svg'
                className='svg-icon-3 svg-icon-primary ms-2'
              />
            </Link>
          </div>
        </div>
    )
}


export {ContactForm}