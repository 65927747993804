import React,{FC} from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

interface Column {
    id: 'Name' | 'G1' | 'A1' | 'G2' | 'A2' | 'G3' | 'A3'| 'G4' | 'A4'| 'G5' | 'A5'| 'G6' | 'A6'| 'G7' | 'A7'| 'G8' | 'A8';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
  const columns: Column[] = [
    { id: 'Name', label: '', minWidth: 50 },

    { id: 'G1', label: 'Goal', minWidth: 50 },
    { id: 'A1', label: 'Achieve', minWidth: 50 },

    { id: 'G2', label: 'Goal', minWidth: 50 },
    { id: 'A2', label: 'Achieve', minWidth: 50 },

    { id: 'G3', label: 'Goal', minWidth: 50 },
    { id: 'A3', label: 'Achieve', minWidth: 50 },

    { id: 'G4', label: 'Goal', minWidth: 50 },
    { id: 'A4', label: 'Achieve', minWidth: 50 },

    { id: 'G5', label: 'Goal', minWidth: 50 },
    { id: 'A5', label: 'Achieve', minWidth: 50 },

    { id: 'G6', label: 'Goal', minWidth: 50 },
    { id: 'A6', label: 'Achieve', minWidth: 50 },

    { id: 'G7', label: 'Goal', minWidth: 50 },
    { id: 'A7', label: 'Achieve', minWidth: 50 },

    { id: 'G8', label: 'Goal', minWidth: 50 },
    { id: 'A8', label: 'Achieve', minWidth: 50 },
  ];
  
  interface Data {
    Name: string;

    G1: string;
    A1: string;

    G2: string;
    A2: string;

    G3: string;
    A3: string;

    G4: string;
    A4: string;

    G5: string;
    A5: string;

    G6: string;
    A6: string;

    G7: string;
    A7: string;

    G8: string;
    A8: string;

  }
  
  function createData(
    Name: string,

    G1: string,
    A1: string,

    G2: string,
    A2: string,

    G3: string,
    A3: string,
    
    G4: string,
    A4: string,

    G5: string,
    A5: string,

    G6: string,
    A6: string,

    G7: string,
    A7: string,

    G8: string,
    A8: string,

  ): Data {
    return { Name, G1, A1, G2, A2, G3, A3, G4, A4, G5, A5, G6, A6, G7, A7, G8, A8, };
  }
  
  const rows = [
    createData('Raj', '101', '200', '190', '60','190', '60','101', '200', '190', '60','101', '200', '190', '60','190', '60'),
    createData('Ram', '101', '200', '190', '60','190', '60','101', '200', '190', '60','101', '200', '190', '60','190', '60'),
    createData('Virat', '101', '200', '190', '60','190', '60','101', '200', '190', '60','101', '200', '190', '60','190', '60'),
    createData('Vijay', '101', '200', '190', '60','190', '60','101', '200', '190', '60','101', '200', '190', '60','190', '60'),
  ];

const UserPerformance: FC = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    return(
        <div>
            <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={1}>
                                    Name
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Total Calls
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Talktime
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Leads Generated
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Leads Converted
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Site Visit
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Meetings
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Bookings
                                </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    Revenue
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ top: 57, minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page} 
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </Paper>
        </div>
    )
}
export{UserPerformance}