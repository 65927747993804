import React,{FC} from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


const MessagePage: FC = () => {
    return(
        <div className="d-flex flex-column flex-center">   
            <img src={toAbsoluteUrl('/media/logos/logo-1.png')} className="under_construction_img" alt='' />
            <h1>Under Construction</h1>
        </div>
    )
}

export {MessagePage}